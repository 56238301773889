.RichText {
  font-size: 1.6rem;
}

.RichText > *:first-child {
  margin-top: 0;
}

.RichText > *:last-child {
  margin-bottom: 0;
}

.RichText h1,
.RichText h2,
.RichText h3,
.RichText h4,
.RichText h5,
.RichText h6 {
  font-family: var(--font-primary);
  line-height: 1.44;
}

.RichText h1 {
  margin-top: 6.2rem;
  margin-bottom: 3.8rem;

  font-size: 3.4rem;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.RichText h2 {
  margin-top: 6.2rem;
  margin-bottom: 3.8rem;

  font-size: 3rem;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.RichText h3 {
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;

  font-size: 1.6rem;
  font-weight: 600;
}

.RichText h4,
.RichText h5,
.RichText h6 {
  margin-top: 1rem;
  margin-bottom: 1rem;

  font-size: 1.4rem;
  font-weight: 600;
}

.RichText p,
.RichText hr {
  margin-top: 2.2rem;
  margin-bottom: 2.2rem;
}

.RichText p:empty {
  display: none;
}

.RichText hr {
  border: 1px solid var(--gray);
}

.RichText img {
  display: block;
  max-width: 100%;
  height: auto;
}

.RichText img.full-width {
  width: 100%;
}

.RichText img.right {
  margin-left: auto;
}

.RichText img.center-small {
  margin-left: auto;
  margin-right: auto;
}

.RichText .text-center {
  text-align: center;
}

.RichText .text-right {
  text-align: right;
}

.RichText .EmbedWrapper {
  display: grid;
  aspect-ratio: 16 / 9;
}

.RichText .EmbedWrapper iframe {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.RichText--withPadding {
  padding: 1.8rem;
}

@media (min-width: 720px) {
  .RichText {
    font-size: 2rem;
    line-height: 1.22;
  }

  .RichText h1 {
    font-size: 6.2rem;
  }

  .RichText h2 {
    font-size: 5.6rem;
  }

  .RichText h3 {
    font-size: 2.6rem;
  }

  .RichText h4,
  .RichText h5,
  .RichText h6 {
    font-size: 2.4rem;
  }
}

/* ASPECT RATIO FALLBACK */

@supports not (aspect-ratio: 1) {
  .RichText .EmbedWrapper::before {
    padding-top: calc(100% / 16 * 9);
  }
}
