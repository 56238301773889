/* This file contains styles for Django-defined elements and classes
   whose templates we can't easily modify.
*/

ul.errorlist {
  padding: 0;
  margin: 0;

  list-style: none;
}

ul.errorlist li {
  font-size: 1.2rem;
  color: var(--danger);
}

ul.errorlist.nonfield li {
  font-size: 1.4rem;
}
