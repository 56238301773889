.Dropdown {
  position: relative;
}

.Dropdown__Content {
  position: absolute;
  top: 100%;
  right: 0;

  display: none;
  padding: 0.8rem 0;

  background-color: var(--background-primary);
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 4px 18px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 8px -2px;
}

.Dropdown--open .Dropdown__Content {
  display: block;
}

.Dropdown__Item {
  display: block;
  padding: 1rem 1.8rem;

  font-size: 1.4rem;
  text-align: right;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
}

.Dropdown__Item:hover,
.Dropdown__Item:focus {
  background-color: var(--light-gray);
}
