.Tags {
  --tags-text-size: 1.8rem;
  --tags-text-color: var(--gray-100);
  --tags-background-color: var(--light-gray);
  --tags-padding-vertical: 0.8rem;
  --tags-padding-horizontal: 2.4rem;
  --tags-spacing: 1rem;

  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--tags-spacing, 1rem) / -2);
}

.Tags--withSeparator {
  --tags-spacing: 0.8rem;
}

.Tag {
  margin: calc(var(--tags-spacing, 1rem) / 2);
}

.Tag__Text {
  display: inline-block;
  padding: var(--tags-padding-vertical, 0.8rem) var(--tags-padding-horizontal);

  font-size: var(--tags-text-size);
  text-decoration: none;
  color: var(--tags-text-color, var(--gray-100));
  background-color: var(--tags-background-color, var(--light-gray));
}

.Tag--withSeparator {
  display: flex;
  align-items: center;
}

.Tag--withSeparator:nth-of-type(1):before {
  content: "\00B7";
  margin-left: 0.3rem;
  margin-right: 0.8rem;
  color: var(--gray-100);
}

.Tag--withSeparator:not(:last-child):after {
  content: "\00B7";
  margin-left: 0.8rem;
  color: var(--gray-100);
}
