.Modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 25;

  display: grid;
  width: 100%;
  height: 100%;
  padding: 2rem 0;

  overflow: auto;
  pointer-events: none;
}

.Modal--open {
  pointer-events: initial;
}

.Modal__Sheet,
.Modal__Content {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
}

.Modal__Sheet {
  width: 100%;
  height: 100;
  margin: -2rem 0;

  background-color: var(--dark-transparent);
}

.Modal__Content {
  width: 100%;
  max-width: 64rem;
  margin: auto;

  color: var(--text-primary);
  background-color: var(--background-primary);
}

.Modal__Sheet,
.Modal__Content {
  opacity: 0;
}

.Modal--open .Modal__Sheet,
.Modal--open .Modal__Content {
  opacity: 1;
}

@media (min-width: 460px) {
  .Modal {
    padding: 2rem;
  }

  .Modal__Sheet {
    width: calc(100% + 4rem);
    margin: -2rem;
  }
}

/* TRANSITIONS */

.Modal__Sheet {
  transition: opacity 150ms ease;
}

.Modal__Content {
  transform: scale(0.75) translateY(25%);
  transition: opacity 200ms ease, transform 200ms ease;
}

.Modal--open .Modal__Content {
  transform: none;
  transition-delay: 100ms, 100ms;
}

@media (prefers-reduced-motion: reduce) {
  .Modal__Sheet,
  .Modal__Content {
    transition: none !important;
  }

  .Modal__Content {
    transform: none !important;
  }
}
