*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  --primary: #111d43;
  --primary-light: #253f95;
  --secondary: #fca720;
  --secondary-light: #f9cc33;

  --light: #fff;
  --dark: #000;
  --light-transparent: rgba(255, 255, 255, 0.3);
  --dark-transparent: rgba(48, 48, 48, 0.75);
  --dark-transparent-100: rgba(48, 48, 48, 0.6);
  --dark-transparent-200: rgba(48, 48, 48, 0.25);
  --dark-transparent-300: rgba(48, 48, 48, 0.15);
  --dark-transparent-350: rgba(0, 0, 0, 0.5);
  --dark-transparent-400: rgba(0, 0, 0, 0.2);
  --dark-transparent-500: rgba(0, 0, 0, 0.1);
  --light-gray: #f1f3f2;
  --gray: #e5e5e5;
  --gray-50: #c1c1c1;
  --gray-100: #767676;
  --danger: #ee0004;
  --success: #39C16C;

  --text-primary: var(--dark);
  --text-primary-light: var(--primary-light);
  --text-inverted: var(--light);

  --background-primary: var(--light);

  --font-primary: "Poppins", sans-serif;
  --font-secondary: "Inter var", "Inter", sans-serif;

  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  margin: 0;

  /* Default font should be --font-secondary in the new design. However to not
   * break the other pages that are not yet redesigned, we keep it --font-primary
   * for now globally, and just override this in each redesigned page's
   * stylesheet. */
  font-family: var(--font-secondary);
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-primary);
  background-color: var(--background-primary);
}

a,
input,
button,
textarea {
  font: inherit;
  color: inherit;
}

body.Page--hideOverflow {
  overflow: hidden;
}
