/* ERROR PAGES */

.Wrapper--errorPage {
  max-width: 46rem;
  padding-top: 10rem;
  padding-bottom: 10rem;

  text-align: center;
}

.ErrorPage__Code {
  margin: 0;
  font-size: 7.4rem;
  letter-spacing: -0.01em;
}

.ErrorPage__Title {
  margin: 0;
  margin-bottom: 1.8rem;

  font-size: 2.4rem;
  text-transform: uppercase;
  letter-spacing: -0.01em;
}

.ErrorPage__Text {
  margin: 0;
  margin-bottom: 6.2rem;

  font-size: 1.4rem;
  line-height: 1.41;
}

@media (min-width: 720px) {
  .Wrapper--errorPage {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .ErrorPage__Code {
    font-size: 10rem;
  }

  .ErrorPage__Title {
    font-size: 3.2rem;
  }

  .ErrorPage__Text {
    font-size: 1.6rem;
  }
}
