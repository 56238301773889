.BookmarkButton {
  flex-shrink: 0;
  cursor: pointer;
}

.BookmarkButton,
.BookmarkButton.BookmarkButton--active:hover {
  width: 2rem;
  height: 2rem;
  border: none;

  font-size: 0;
  background: transparent url("/static/images/ic-bookmark.svg") center center no-repeat;
  background-size: contain;
}

.BookmarkButton.BookmarkButton--active,
.BookmarkButton:hover {
  background-image: url("/static/images/ic-bookmark-fill.svg");
}

.Bookmark--hidden {
  display: none;
}
