.Heading {
  font-family: var(--font-primary);
  line-height: 1.4;
}

h1.Heading,
.Heading--h1 {
  font-size: 3.4rem;
  font-weight: 700;
  letter-spacing: -0.01em;
}

h2.Heading,
.Heading--h2 {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: -0.01em;
}

h3.Heading,
.Heading--h3 {
  font-size: 1.6rem;
  font-weight: 600;
}

h4.Heading,
h5.Heading,
h6.Heading,
.Heading--h4,
.Heading--h5,
.Heading--h6 {
  font-size: 1.4rem;
  font-weight: 500;
}

@media (min-width: 720px) {
  .Heading {
    line-height: 1.22;
  }

  h1.Heading,
  .Heading--h1 {
    font-size: 6.2rem;
  }

  h2.Heading,
  .Heading--h2 {
    font-size: 5.6rem;
  }

  h3.Heading,
  .Heading--h3 {
    font-size: 2.6rem;
  }

  h4.Heading,
  h5.Heading,
  h6.Heading,
  .Heading--h4,
  .Heading--h5,
  .Heading--h6 {
    font-size: 2.4rem;
  }
}

/* HEADING PADDINGS */

.Heading--block {
  padding-top: var(--heading-padding-top);
  padding-bottom: var(--heading-padding-bottom);
  margin: 0;
}

h1.Heading.Heading--block,
h2.Heading.Heading--block,
.Heading--h1.Heading--block,
.Heading--h2.Heading--block {
  --heading-padding-top: 4.2rem;
  --heading-padding-bottom: 2rem;
}

h3.Heading.Heading--block,
.Heading--h3.Heading--block {
  --heading-padding-top: 1.4rem;
  --heading-padding-bottom: 1.4rem;
}

h4.Heading.Heading--block,
h5.Heading.Heading--block,
h6.Heading.Heading--block,
.Heading--h4.Heading--block,
.Heading--h5.Heading--block,
.Heading--h6.Heading--block  {
  --heading-padding-top: 1rem;
  --heading-padding-bottom: 1rem;
}

@media (min-width: 720px) {
  h1.Heading.Heading--block,
  h2.Heading.Heading--block,
  .Heading--h1.Heading--block,
  .Heading--h2.Heading--block {
    --heading-padding-top: 6.2rem;
    --heading-padding-bottom: 3.8rem;
  }
}
