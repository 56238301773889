.SiteBreadCrumbs {
  display: none;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}

.SiteBreadCrumbs__Links {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.6rem;
  padding: 0;
  margin: 0;

  list-style: none;
}

.SiteBreadCrumbs__Item {
  display: flex;
  align-items: center;
}

.SiteBreadCrumbs__Item--ellipsis {
  font-weight: 700;
  line-height: 1;
  overflow: visible;
}

.SiteBreadCrumbs__Item:not(:first-child)::before {
  content: "";
  flex-shrink: 0;
  display: block;
  width: 5px;
  height: 1rem;
  margin-right: 1.6rem;

  background: url("/static/images/ic-arrow-forward-dark.svg") center center no-repeat;
  background-size: 100% auto;
}

.SiteBreadCrumbs__Item:last-child {
  color: var(--primary-light);
}

.SiteBreadCrumbs__Link {
  display: block;
  max-width: 30rem;

  font-size: 1.6rem;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SiteBreadCrumbs__Link:hover,
.SiteBreadCrumbs__Link:focus {
  text-decoration: underline;
}

.SiteBreadCrumbs__Item:last-child .SiteBreadCrumbs__Link:hover {
  text-decoration: none;
}

.SiteBreadCrumbs__Dropdown {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.SiteBreadCrumbs__Dropdown:hover {
  text-decoration: underline;
}

.SiteBreadCrumbs__Dropdown__Content {
  z-index: 1;
  min-width: 30rem;
  left: 0;
  right: auto;
}

.SiteBreadCrumbs__Dropdown__Item {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  text-transform: none;
  white-space: normal;
}

@media (min-width: 720px) {
  .SiteBreadCrumbs {
    display: block;
  }
}
