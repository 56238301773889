.Wrapper {
  --wrapper-size: 100vw;

  box-sizing: content-box;
  max-width: 144rem;
  margin: 0 auto;
}

.Wrapper:not(.Wrapper--inner) {
  --wrapper-size: calc(100vw - 4rem);

  padding-left: 2rem;
  padding-right: 2rem;
}

.Wrapper--enableGrid {
  --wrapper-grid-columns: 4;
  --wrapper-grid-gap-size: 2rem;
  --wrapper-grid-column-size: calc(
    (var(--wrapper-size) - (3 * var(--wrapper-grid-gap-size))) / 4
  );

  display: grid;
  grid-template-columns: repeat(var(--wrapper-grid-columns), 1fr);
  gap: var(--wrapper-grid-gap-size);
}

@media (min-width: 720px) {
  .Wrapper:not(.Wrapper--inner) {
    --wrapper-size: calc(100vw - 6rem);

    padding-left: 3rem;
    padding-right: 3rem;
  }

  .Wrapper--enableGrid {
    --wrapper-grid-columns: 12;
    --wrapper-grid-gap-size: 3rem;
    --wrapper-grid-column-size: calc(
      (var(--wrapper-size) - (11 * var(--wrapper-grid-gap-size))) / 12
    );
  }
}

@media (min-width: 1280px) {
  .Wrapper:not(.Wrapper--inner) {
    --wrapper-size: calc(100vw - 20rem);

    padding-left: 10rem;
    padding-right: 10rem;
  }
}

@media (min-width: 1640px) {
  .Wrapper {
    --wrapper-size: 164rem;
  }

  .Wrapper:not(.Wrapper--inner) {
    --wrapper-size: calc(164rem - 20rem);

    padding-left: 10rem;
    padding-right: 10rem;
  }
}
