.Button,
.Button--small,
.Button--responsive.Button--small,
.Button--responsive.Button--medium,
.Button--responsive.Button--large {
  --button-size: 4.6rem;
  --button-text-size: 1.6rem;
  --button-text-weight: 700;
  --button-border-size: 3px;
  --button-padding-horizontal: 3rem;
  --button-padding-vertical: 1rem;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: var(--button-size, 4.6rem);
  padding: var(--button-padding-vertical, 1rem) var(--button-padding-horizontal, 3rem);
  border: var(--button-border-size, 3px) solid var(--button-border-color, var(--light));

  font-family: var(--font-primary);
  font-size: var(--button-text-size, 1.6rem);
  font-weight: var(--button-text-weight, 700);
  text-align: center;
  text-decoration: none;
  color: var(--button-text-color, var(--light));

  background-color: var(--button-background-color, transparent);
  cursor: pointer;
}

.Button--medium {
  --button-size: 5.4rem;
  --button-text-size: 2rem;
  --button-border-size: 5px;
}

.Button--large {
  --button-size: 6rem;
  --button-text-size: 2.4rem;
  --button-border-size: 5px;
}

@media (min-width: 720px) {
  .Button--responsive.Button--medium,
  .Button--responsive.Button--large {
    --button-size: 5.4rem;
    --button-text-size: 2rem;
    --button-border-size: 4px;
  }
}

@media (min-width: 1024px) {
  .Button--responsive.Button--large {
    --button-size: 6rem;
    --button-text-size: 2.4rem;
    --button-border-size: 5px;
  }
}

/* BUTTON STATES */

.Button {
  --button-text-color: var(--light);
  --button-border-color: var(--light);
  --button-background-color: transparent;
  --button-highlight-text-color: var(--dark);
  --button-highlight-border-color: var(--secondary-light);
  --button-highlight-background-color: var(--secondary-light);
  --button-disabled-opacity: 0.5;
}

.Button:disabled {
  opacity: var(--button-disabled-opacity, 0.5);
  cursor: not-allowed;
}

.Button:not(:disabled):hover,
.Button:not(:disabled):focus {
  --button-text-color: var(--button-highlight-text-color, var(--dark));
  --button-border-color: var(--button-highlight-border-color, var(--secondary-light));
  --button-background-color: var(--button-highlight-background-color, var(--secondary-light));
}

/* BUTTON THEMES */

.Button--secondary {
  --button-highlight-text-color: var(--dark);
  --button-highlight-border-color: var(--light);
  --button-highlight-background-color: var(--light);
}

.Button--dark {
  --button-text-color: var(--dark);
  --button-border-color: var(--dark);
}

.Button--dark.Button--secondary {
  --button-highlight-text-color: var(--light);
  --button-highlight-border-color: var(--dark);
  --button-highlight-background-color: var(--dark);
}

/* BUTTON VARIANTS */

.Button--icon {
  width: var(--button-size);
  padding: 0;

  background: center center no-repeat;
  background-size: calc(var(--button-size) / 3 * 2);
}

.Button.Button--icon {
  --button-text-size: 0;
  --button-border-size: 0;
}

.Button.Button--text {
  --button-border-color: transparent;
}

.Button.Button--full {
  display: flex;
}
