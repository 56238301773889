.Page,
.Page--headerDoubleRow {
  /* This variable is defined on the body/page element instead of scoping
   * just inside the header so that other page blocks and scripts can access
   * and make calculations based on this property.
   */
  --header-offset: 0;
  --header-scroll-progress: 0;
  --header-banner-height: 85;
  --site-header-size: 10rem;
}

@media (min-width: 720px) {
  .Page,
  .Page--headerDoubleRow {
    --site-header-size: 12rem;
  }
}

@media (min-width: 1024px) {
  .Page--headerDoubleRow {
    --site-header-size: 20rem;
  }
}

/* SITE HEADER */

.SiteHeader {
  position: sticky;
  top: 0;
  z-index: 20;

  font-family: var(--font-primary);
  background-color: var(--header-background-color, var(--background-primary));
  box-shadow: 0 1px 1px var(--gray);
  transition: box-shadow 250ms ease;
}

.SiteHeader--hasScrolled {
  box-shadow:
    0 1px 2px 0 var(--dark-transparent-200),
    0 2px 6px 2px var(--dark-transparent-300);
}

.SiteHeader__Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: var(--site-header-size);
}

.SiteLogo__Image {
  display: block;
  width: auto;
  max-width: 42rem;
  max-height: calc(var(--site-header-size) - 4.8rem);
  object-fit: contain;
  object-position: center center;
}

.SiteNav {
  /* Make sure the site nav content aligns with the edge of the wrapper.  */
  margin-right: -0.6rem;
}

.SiteNav__Toggle {
  background-image: url("/static/images/ic-menu-dark.svg");
}

.SiteNav--open .SiteNav__Toggle {
  position: relative;
  z-index: 21;

  background-image: url("/static/images/ic-close-light.svg");
}

@supports (mask-image: url("")) or (-webkit-mask-image: url("")) {
  .SiteNav__Toggle,
  .SiteNav--open .SiteNav__Toggle {
    display: flex;
    justify-content: center;
    align-items: center;

    background: none;
  }

  .SiteNav__Toggle::before {
    content: "";
    display: block;
    width: 3.2rem;
    height: 3.2rem;

    -webkit-mask-image: url("/static/images/ic-menu-dark.svg");
    mask-image: url("/static/images/ic-menu-dark.svg");
    background-color: var(--header-text-color, var(--primary-light));
  }

  .SiteNav--open .SiteNav__Toggle::before {
    -webkit-mask-image: url("/static/images/ic-close-light.svg");
    mask-image: url("/static/images/ic-close-light.svg");
    background-color: var(--header-text-color, var(--light));
  }
}

.SiteNav__Sheet {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  cursor: pointer;
  overflow: hidden;
  pointer-events: none;
}

.SiteNav--open .SiteNav__Sheet {
  pointer-events: initial;
}

.SiteNav__Links {
  display: flex;
  flex-direction: column;

  position: relative;
  width: 100%;
  height: 100%;
  padding: 8rem 2rem;

  color: var(--header-text-color, var(--text-inverted));
  background-color: var(--header-background-color, var(--primary-light));
  opacity: 0;
  overflow: auto;
  cursor: auto;
}

.SiteNav--open .SiteNav__Links {
  opacity: 1;
}

.SiteNav__MainLinks {
  display: flex;
  flex-direction: column;
}

.SiteNav__Link {
  padding: 1.1rem 1.2rem;

  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  text-decoration: none;
  text-transform: uppercase;
}

.SiteNav__Link--doubleRow {
  text-transform: capitalize;
  color: var(--secondary-light);
}

.SiteNav__Link__Text {
  position: relative;
}

.SiteNav__Link:hover .SiteNav__Link__Text::after,
.SiteNav__Link:focus .SiteNav__Link__Text::after,
.SiteNav__Link--current .SiteNav__Link__Text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-width: 3rem;
  height: 0.3rem;

  background-color: var(--header-accent-color, var(--secondary-light));
}

.SiteNav--open .SiteNav__OtherLinks {
  order: 1;
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

@media (min-width: 720px) {
  .SiteNav--open .SiteNav__Toggle {
    position: initial;
    background-image: url("/static/images/ic-menu-dark.svg");
  }

  @supports (mask-image: url("")) or (-webkit-mask-image: url("")) {
    .SiteNav--open .SiteNav__Toggle {
      background: none;
    }

    .SiteNav--open .SiteNav__Toggle::before {
      -webkit-mask-image: url("/static/images/ic-menu-dark.svg");
      mask-image: url("/static/images/ic-menu-dark.svg");
      background-color: var(--header-text-color, var(--primary-light));
    }
  }

  .SiteNav__Sheet {
    background: none;
  }

  .SiteNav--open .SiteNav__Sheet {
    background-color: var(--dark-transparent);
  }

  .SiteNav__Links {
    width: 36rem;
    opacity: 0;
  }

  .SiteNav--open .SiteNav__Links {
    opacity: 1;
  }
}

@media (min-width: 1024px) {
  .Page--headerDoubleRow .SiteHeader {
    --header-logo-offset-max: var(--header-banner-height);
    --header-logo-scale-min: 0.6;

    top: calc(var(--header-banner-height) * -1px + var(--header-offset) * 1px);

    font-family: var(--font-primary);
    background-color: var(--header-background-color, var(--background-primary));
    box-shadow: 0 1px 1px var(--gray);
    transition: box-shadow 250ms ease;
  }

  .SiteHeader--hasScrolled {
    box-shadow:
      0 1px 2px 0 var(--dark-transparent-200),
      0 2px 6px 2px var(--dark-transparent-300);
  }

  .SiteHeader__Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: var(--site-header-size);
  }

  .Page--headerDoubleRow .SiteLogo {
    margin-top: calc(
      (var(--header-logo-offset-max) * var(--header-scroll-progress) * 1px) /* set in JS based on page scroll */
      - (var(--header-offset) * 1px) /* take into account the vertical offset of entire banner */
    );
  }

  .Page--headerDoubleRow .SiteLogo__Image {
    display: block;
    width: auto;
    max-width: 42rem;
    max-height: calc(var(--site-header-size) - 4.8rem);
    object-fit: contain;
    object-position: center center;

    transform: scale(calc(
      1 - (1 - var(--header-logo-scale-min))
        * (var(--header-scroll-progress) /* set in JS based on page scroll */
        - (var(--header-offset) / var(--header-banner-height))) /* take into account the vertical offset of entire banner */
    ));
    transform-origin: left center;
  }

  .SiteNav {
    margin-right: -1.4rem;
  }

  .SiteNav__Toggle,
  .SiteNav--open .SiteNav__Toggle {
    display: none;
  }

  .SiteNav__Sheet,
  .SiteNav--open .SiteNav__Sheet {
    position: initial;
    display: block;

    background: none;
    pointer-events: initial;
    overflow: initial;
  }

  .SiteNav__Links {
    flex-direction: row;
    align-items: center;
    width: auto;
    padding: 0;

    color: var(--header-text-color, var(--text-primary));
    background-color: var(--header-background-color, transparent);
    opacity: 1;
    overflow: initial;
  }

  .SiteNav__Links--withOtherLinks {
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(2, auto);
    row-gap: 5.2rem
  }

  .SiteNav__MainLinks {
    flex-direction: row;
  }

  .SiteNav__OtherLinks,
  .SiteNav--open .SiteNav__OtherLinks {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
  }

  .SiteNav__OtherLinks .SiteNav__Link:first-child {
    margin: 0;
  }

  .SiteNav__OtherLinks .SiteNav__Link:last-child {
    margin-right: 2.2rem;
  }

  .SiteNav__OtherLinks .Dropdown__Item.SiteNav__Link:last-child {
    margin-right: 0;
  }

  .SiteNav__Link {
    font-size: 1.8rem;
    text-align: center;
  }

  .SiteNav__Link:not(:last-child) {
    margin-right: 3.2rem;
    margin-bottom: 0;
  }

  .SiteNav__Link__Text {
    display: inline-block;
  }

  .SiteNav__Link--current .SiteNav__Link__Text {
    color: var(--header-accent-color, var(--secondary-light));
  }

  .SiteNav__Link:hover .SiteNav__Link__Text::after,
  .SiteNav__Link:focus .SiteNav__Link__Text::after,
  .SiteNav__Link--current .SiteNav__Link__Text::after {
    content: "";
    position: absolute;
    top: calc(6px + var(--site-header-size) / 2);
    left: 50%;
    right: 0;
    width: 100%;
    max-width: none;
    height: 0.8rem;

    background-color: var(--header-accent-color, var(--secondary-light));
    transform: translate(-50%);
  }

  .Page--headerDoubleRow .SiteNav__Link:hover .SiteNav__Link__Text::after,
  .Page--headerDoubleRow .SiteNav__Link:focus .SiteNav__Link__Text::after,
  .Page--headerDoubleRow .SiteNav__Link--current .SiteNav__Link__Text::after {
    top: calc(5px + var(--site-header-size) / 4);
  }
}

/* CURRENT USER DROPDOWN */

@media (max-width: 1023px) {
  .SiteNav__CurrentUser {
    padding: 0;
    border: none;
    margin: 0 -2rem;
    margin-top: auto;

    background: none;
  }

  .SiteNav__CurrentUser::before {
    display: flex;
    content: "";
    margin: 2.8rem 3.2rem;
    border-top: 0.1rem solid var(--light);
  }

  .SiteNav__CurrentUser__Name {
    display: block;
    padding: 1.1rem 3.2rem;

    font-size: 2rem;
    font-weight: 600;
    text-align: left;
  }

  .SiteNav__CurrentUser .Dropdown__Content,
  .SiteNav__CurrentUser.Dropdown--open .Dropdown__Content {
    position: initial;
    display: block;
    padding: 0 2rem;

    background: none;
    box-shadow: none;
  }

  .SiteNav__CurrentUser .Dropdown__Item {
    padding: 1.1rem 1.2rem;

    font-size: 1.8rem;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
    text-transform: none;
    background: none;
  }

  .SiteNav__CurrentUser .Dropdown__Item__Text {
    position: relative;
  }

  .SiteNav__CurrentUser .SiteNav__Link:hover .Dropdown__Item__Text::after,
  .SiteNav__CurrentUser .SiteNav__Link:focus .Dropdown__Item__Text::after,
  .SiteNav__CurrentUser .SiteNav__Link--current .Dropdown__Item__Text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-width: 3rem;
    height: 0.3rem;

    background-color: var(--header-accent-color, var(--secondary-light));
  }
}

@media (min-width: 1024px) {
  .SiteNav__CurrentUser {
    display: flex;
    align-items: center;
    padding: 1.1rem 1.2rem;
    border: none;

    background: none;
    cursor: pointer;
  }

  .SiteNav__CurrentUser::after {
    content: "";
    width: 2.4rem;
    height: 2.4rem;
    margin-left: 0.4rem;
    margin-right: -0.4rem;

    background: url("/static/images/ic-chevron-down-light.svg") center center no-repeat;
  }

  @supports (mask-image: url("")) or (-webkit-mask-image: url("")) {
    .SiteNav__CurrentUser::after {
      -webkit-mask-image: url("/static/images/ic-chevron-down-light.svg");
      mask-image: url("/static/images/ic-chevron-down-light.svg");
      background: none;
      background-color: var(--header-text-color, var(--text-primary));
    }
  }

  .SiteNav__CurrentUser__Name {
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: -0.01em;
  }

  .SiteNav__CurrentUser:hover .SiteNav__CurrentUser__Name,
  .SiteNav__CurrentUser:focus .SiteNav__CurrentUser__Name{
    color: var(--header-accent-color, var(--secondary-light));
  }

  .SiteNav__CurrentUser .Dropdown__Content {
    min-width: 18rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .SiteNav__OtherLinks .SiteNav__CurrentUser .Dropdown__Content,
  .SiteNav__OtherLinks .SiteNav__CurrentUser.Dropdown--open .Dropdown__Content {
    z-index: 1;
    min-width: 20rem;
    padding: 1.2rem 0.8rem;
    border-radius: 0.6rem;
  }

  .SiteNav__CurrentUser.Dropdown--open::after {
    transform: rotate(180deg);
  }

  .SiteNav__CurrentUser .Dropdown__Item {
    padding: 1rem 1.8rem;
    margin: 0;

    font-size: 1.6rem;
    font-weight: 400;
    text-decoration: none;
    text-transform: none;
    text-align: left;
    color: var(--text-primary);
  }

  .SiteNav__OtherLinks .SiteNav__CurrentUser .Dropdown__Item,
  .SiteNav__OtherLinks .SiteNav__CurrentUser .Dropdown__Item {
    padding: 1.2rem 2.2rem;
    border-radius: 0.6rem;
  }

  .SiteNav__OtherLinks .SiteNav__CurrentUser .Dropdown__Item:hover,
  .SiteNav__OtherLinks .SiteNav__CurrentUser .Dropdown__Item:focus {
    color: var(--light);
    background-color: var(--secondary-light);
  }
}

/* BACK LINK */

.SiteNav__BackLink {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.SiteNav__BackLink--userIsAuthenticated {
  margin-top: 0;
}

.SiteNav__BackLink::before {
  content: "";
  display: block;
  border-top: 0.1rem solid var(--light);
  margin: 2.8rem 0;
}

.SiteNav__Link--backLink {
  font-size: 1.8rem;
  font-weight: 400;
  text-transform: none;
}

.SiteNav__Link--backLink:hover {
  color: var(--header-accent-color, var(--secondary-light));
}

.SiteNav__Link--backLinkWithOtherLinks {
  font-size: 1.6rem;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .SiteNav__Link--backLinkWithOtherLinks {
    padding: 0.7rem 2.1rem;
    border-radius: 4rem;
    background: rgba(255, 255, 255, 0.2);
  }

  .SiteNav__BackLink {
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .SiteNav__BackLink::before {
    height: 2rem;
    border: 0;
    border-left: 0.1rem solid var(--light);
    margin: 0 1.8rem;
  }

  .SiteNav__BackLink--withOtherLinks::before {
    height: 0;
  }
}

/* TRANSITIONS */

.SiteNav__Sheet::before {
  content: "";
  position: absolute;
  top: 2.2rem;
  right: 1.4rem;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;

  background-color: var(--header-background-color, var(--primary-light));
  opacity: 0;

  transform-origin: center center;
  transition: transform 300ms ease, opacity 100ms ease;
}

.SiteNav--open .SiteNav__Sheet::before {
  opacity: 1;
  transform: scale(100);
  transition-duration: 500ms, 300ms;
}

.SiteNav__Links {
  transform: translateY(2rem);
}

.SiteNav--open .SiteNav__Links {
  transform: translateY(0);
  transition: opacity 250ms ease, transform 250ms ease;
  transition-delay: 200ms, 180ms;
}

@media (min-width: 720px) {
  .SiteNav__Sheet::before {
    display: none;
  }

  .SiteNav--open .SiteNav__Sheet {
    transition: background-color 150ms ease;
  }

  .SiteNav__Links {
    transform: translateX(-100%);
    transition: transform 150ms ease;
  }

  .SiteNav--open .SiteNav__Links {
    transform: translateX(0);
    transition: transform 150ms ease;
  }
}

@media (min-width: 1024px) {
  .SiteNav__Links {
    transform: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .SiteNav__Sheet::before,
  .SiteNav__Sheet,
  .SiteNav__Links {
    transition: none !important;
  }
}
