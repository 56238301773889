.ToastContainer {
  --margin-x: 2rem;
  --margin-y: 4rem;
  --offset-x: 0rem;

  position: fixed;
  right: calc(var(--margin-x) + var(--offset-x));
  bottom: var(--margin-y);

  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: calc(100% - (2 * var(--margin-x) + var(--offset-x)));
  max-width: calc(100% - (2 * var(--margin-x) + var(--offset-x)));

  z-index: 1;
}

.wagtail-userbar-reset ~ .ToastContainer {
  --margin-y: 2em;
  --offset-x: calc(3.5em + 2em);

  min-height: 3.5em;
}

.Toast {
  display: flex;
  align-items: center;
  min-height: 8rem;
  padding: 2rem 2.6rem;
  border: 2px solid var(--gray);
  border-radius: 0.8rem;

  background-color: var(--light);
  box-shadow: 1px 0px 1.6rem 0 var(--dark-transparent-500);
}

.Toast:not(:last-child) {
  margin-bottom: 0.8rem;
}

.Toast__Icon {
  flex-shrink: 0;
  display: block;
  width: 2.4rem;
  height: auto;
  margin-right: 1rem;
}

.Toast__Message {
  margin: 0;
  margin-right: auto;
  font-size: 1.4rem;
}

.Toast__Message a {
  text-decoration: none;
  color: var(--primary-light);
}

.Toast__Dismiss {
  flex-shrink: 0;
  display: block;
  width: 2rem;
  height: 2rem;
  border: none;
  margin-left: 1rem;

  background: transparent url("/static/images/ic-close-dark.svg") center center no-repeat;
  background-size: 1.2rem;
  cursor: pointer;
}

@media (min-width: 720px) {
  .ToastContainer {
    min-width: 53rem;
  }
}
