*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  --primary: #111d43;
  --primary-light: #253f95;
  --secondary: #fca720;
  --secondary-light: #f9cc33;

  --light: #fff;
  --dark: #000;
  --light-transparent: rgba(255, 255, 255, 0.3);
  --dark-transparent: rgba(48, 48, 48, 0.75);
  --dark-transparent-100: rgba(48, 48, 48, 0.6);
  --dark-transparent-200: rgba(48, 48, 48, 0.25);
  --dark-transparent-300: rgba(48, 48, 48, 0.15);
  --dark-transparent-350: rgba(0, 0, 0, 0.5);
  --dark-transparent-400: rgba(0, 0, 0, 0.2);
  --dark-transparent-500: rgba(0, 0, 0, 0.1);
  --light-gray: #f1f3f2;
  --gray: #e5e5e5;
  --gray-50: #c1c1c1;
  --gray-100: #767676;
  --danger: #ee0004;
  --success: #39C16C;

  --text-primary: var(--dark);
  --text-primary-light: var(--primary-light);
  --text-inverted: var(--light);

  --background-primary: var(--light);

  --font-primary: "Poppins", sans-serif;
  --font-secondary: "Inter var", "Inter", sans-serif;

  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  margin: 0;

  /* Default font should be --font-secondary in the new design. However to not
   * break the other pages that are not yet redesigned, we keep it --font-primary
   * for now globally, and just override this in each redesigned page's
   * stylesheet. */
  font-family: var(--font-secondary);
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-primary);
  background-color: var(--background-primary);
}

a,
input,
button,
textarea {
  font: inherit;
  color: inherit;
}

body.Page--hideOverflow {
  overflow: hidden;
}

.Page {
  display: flex;
  flex-direction: column;
}

.SiteHeader,
.SiteFooter {
  flex-shrink: 0;
}

.SiteContent {
  flex-grow: 1;
}

.Wrapper {
  --wrapper-size: 100vw;

  box-sizing: content-box;
  max-width: 144rem;
  margin: 0 auto;
}

.Wrapper:not(.Wrapper--inner) {
  --wrapper-size: calc(100vw - 4rem);

  padding-left: 2rem;
  padding-right: 2rem;
}

.Wrapper--enableGrid {
  --wrapper-grid-columns: 4;
  --wrapper-grid-gap-size: 2rem;
  --wrapper-grid-column-size: calc(
    (var(--wrapper-size) - (3 * var(--wrapper-grid-gap-size))) / 4
  );

  display: grid;
  grid-template-columns: repeat(var(--wrapper-grid-columns), 1fr);
  gap: var(--wrapper-grid-gap-size);
}

@media (min-width: 720px) {
  .Wrapper:not(.Wrapper--inner) {
    --wrapper-size: calc(100vw - 6rem);

    padding-left: 3rem;
    padding-right: 3rem;
  }

  .Wrapper--enableGrid {
    --wrapper-grid-columns: 12;
    --wrapper-grid-gap-size: 3rem;
    --wrapper-grid-column-size: calc(
      (var(--wrapper-size) - (11 * var(--wrapper-grid-gap-size))) / 12
    );
  }
}

@media (min-width: 1280px) {
  .Wrapper:not(.Wrapper--inner) {
    --wrapper-size: calc(100vw - 20rem);

    padding-left: 10rem;
    padding-right: 10rem;
  }
}

@media (min-width: 1640px) {
  .Wrapper {
    --wrapper-size: 164rem;
  }

  .Wrapper:not(.Wrapper--inner) {
    --wrapper-size: calc(164rem - 20rem);

    padding-left: 10rem;
    padding-right: 10rem;
  }
}

/* ASPECT RATIO FALLBACK */
/* Assuming that @supports is supported is fine, since we're also using CSS Grid which came out later. */

@supports not (aspect-ratio: 1) {
  .AspectRatio__Media {
    position: relative;
  }

  .AspectRatio__Media::before {
    content: "";
    display: block;
  }

  .AspectRatio__Image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.Grid {
  display: grid;
  gap: 2rem;
}

.Col--sm0  { grid-column: 1 / -1;  }
.Col--sm1  { grid-column: auto / span 1;  }
.Col--sm2  { grid-column: auto / span 2;  }
.Col--sm3  { grid-column: auto / span 3;  }
.Col--sm4  { grid-column: auto / span 4;  }
.Col--sm5  { grid-column: auto / span 5;  }
.Col--sm6  { grid-column: auto / span 6;  }
.Col--sm7  { grid-column: auto / span 7;  }
.Col--sm8  { grid-column: auto / span 8;  }
.Col--sm9  { grid-column: auto / span 9;  }
.Col--sm10 { grid-column: auto / span 10; }
.Col--sm11 { grid-column: auto / span 11; }
.Col--sm12 { grid-column: auto / span 12; }

@media (min-width: 720px) {
  .Grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }

  .Grid[data-items="1"] {
    grid-template-columns: 0.5fr 1fr 0.5fr;
  }

  .Grid[data-items="1"]::before,
  .Grid[data-items="1"]::after {
    content: "";
  }

  .Col--md0  { grid-column: 1/ -1;  }
  .Col--md1  { grid-column: auto / span 1;  }
  .Col--md2  { grid-column: auto / span 2;  }
  .Col--md3  { grid-column: auto / span 3;  }
  .Col--md4  { grid-column: auto / span 4;  }
  .Col--md5  { grid-column: auto / span 5;  }
  .Col--md6  { grid-column: auto / span 6;  }
  .Col--md7  { grid-column: auto / span 7;  }
  .Col--md8  { grid-column: auto / span 8;  }
  .Col--md9  { grid-column: auto / span 9;  }
  .Col--md10 { grid-column: auto / span 10; }
  .Col--md11 { grid-column: auto / span 11; }
  .Col--md12 { grid-column: auto / span 12; }
}

@media (min-width: 1024px) {
  .Grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .Grid[data-items="1"] {
    grid-template-columns: 1.5fr minmax(36rem, 1fr) 1.5fr;
  }

  .Grid[data-items="2"] {
    grid-template-columns: 1fr repeat(2, minmax(36rem, 1fr)) 1fr;
  }

  .Grid[data-items="3"] {
    grid-template-columns: 0.5fr repeat(3, minmax(26rem, 1fr)) 0.5fr;
  }

  .Grid[data-items="2"]::before,
  .Grid[data-items="2"]::after,
  .Grid[data-items="3"]::before,
  .Grid[data-items="3"]::after {
    content: "";
  }

  .Col--lg0  { grid-column: 1 / -1;  }
  .Col--lg1  { grid-column: auto / span 1;  }
  .Col--lg2  { grid-column: auto / span 2;  }
  .Col--lg3  { grid-column: auto / span 3;  }
  .Col--lg4  { grid-column: auto / span 4;  }
  .Col--lg5  { grid-column: auto / span 5;  }
  .Col--lg6  { grid-column: auto / span 6;  }
  .Col--lg7  { grid-column: auto / span 7;  }
  .Col--lg8  { grid-column: auto / span 8;  }
  .Col--lg9  { grid-column: auto / span 9;  }
  .Col--lg10 { grid-column: auto / span 10; }
  .Col--lg11 { grid-column: auto / span 11; }
  .Col--lg12 { grid-column: auto / span 12; }
}

/* This file contains styles for Django-defined elements and classes
   whose templates we can't easily modify.
*/

ul.errorlist {
  padding: 0;
  margin: 0;

  list-style: none;
}

ul.errorlist li {
  font-size: 1.2rem;
  color: var(--danger);
}

ul.errorlist.nonfield li {
  font-size: 1.4rem;
}

/* ERROR PAGES */

.Wrapper--errorPage {
  max-width: 46rem;
  padding-top: 10rem;
  padding-bottom: 10rem;

  text-align: center;
}

.ErrorPage__Code {
  margin: 0;
  font-size: 7.4rem;
  letter-spacing: -0.01em;
}

.ErrorPage__Title {
  margin: 0;
  margin-bottom: 1.8rem;

  font-size: 2.4rem;
  text-transform: uppercase;
  letter-spacing: -0.01em;
}

.ErrorPage__Text {
  margin: 0;
  margin-bottom: 6.2rem;

  font-size: 1.4rem;
  line-height: 1.41;
}

@media (min-width: 720px) {
  .Wrapper--errorPage {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .ErrorPage__Code {
    font-size: 10rem;
  }

  .ErrorPage__Title {
    font-size: 3.2rem;
  }

  .ErrorPage__Text {
    font-size: 1.6rem;
  }
}


.BookmarkButton {
  flex-shrink: 0;
  cursor: pointer;
}

.BookmarkButton,
.BookmarkButton.BookmarkButton--active:hover {
  width: 2rem;
  height: 2rem;
  border: none;

  font-size: 0;
  background: transparent url("/static/images/ic-bookmark.svg") center center no-repeat;
  background-size: contain;
}

.BookmarkButton.BookmarkButton--active,
.BookmarkButton:hover {
  background-image: url("/static/images/ic-bookmark-fill.svg");
}

.Bookmark--hidden {
  display: none;
}

/* FILTERS DROPDOWNS */

.Filters__Form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 0;
  padding-bottom: 2.8rem;
}

.Filters__Dropdowns {
  display: flex;
  flex-wrap: wrap;
  margin-right: 3px;
  margin-bottom: 3px;
}

.Filters__Dropdown {
  display: flex;
  align-items: center;
  padding: 0.85rem 2.2rem;
  border: 3px solid var(--block-text-color, var(--text-primary));
  margin: 0;
  margin-right: -3px;
  margin-bottom: -3px;

  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  background: var(--light);
  cursor: pointer;
}

.Filters__Dropdown:hover,
.Filters__Dropdown.Dropdown--open {
  color: var(--light);
  background: var(--block-text-color, var(--text-primary));
}

.Filters__Dropdown::after {
  content: "";
  width: 2.2rem;
  height: 2.2rem;

  background: url("/static/images/ic-chevron-down-light.svg") center center no-repeat;
}

@supports (mask-image: url("")) or (-webkit-mask-image: url("")) {
  .Filters__Dropdown::after {
    -webkit-mask-image: url("/static/images/ic-chevron-down-light.svg");
    mask-image: url("/static/images/ic-chevron-down-light.svg");
    background: none;
    background-color: currentColor;
  }
}

.Filters__DropdownContent {
  min-width: calc(100vw - 6rem);
  max-height: calc(100vh / 3);
  padding: 1.8rem 2.2rem !important;
  border: 3px solid var(--block-text-color, var(--text-primary));
  z-index: 1;
  box-shadow: 0.2rem 1.4rem 2rem var(--dark-transparent-400);
  overflow-y: scroll;
}

.Filters__Choice {
  display: flex;
  justify-content: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;

  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: left;
  text-transform: none;
  user-select: none;
  color: var(--block-text-color, var(--text-primary));
  cursor: pointer;
}

.Filters__Checkbox {
  -webkit-appearance: none;
  appearance: none;
  flex-shrink: 0;

  display: flex;
  width: 2rem;
  height: 2rem;
  border: 2px solid var(--gray);
  margin: 0;
  margin-right: 1.6rem;

  color: var(--light);
  background-color: var(--light);
  cursor: pointer;
}

.Filters__Choice:hover .Filters__Checkbox {
  color: var(--gray);
  border-color: var(--block-text-color, var(--text-primary));
  background-color: var(--light);
}

.Filters__Choice .Filters__Checkbox:checked {
  color: var(--light);
  border-color: var(--block-text-color, var(--text-primary));
  background: url("/static/images/ic-check-light.svg") center center no-repeat;
  background-color: var(--block-text-color, var(--text-primary));
}

@supports (mask-image: url("")) or (-webkit-mask-image: url("")) {
  .Filters__Checkbox::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;

    -webkit-mask-image: url("/static/images/ic-check-light.svg");
    -webkit-mask-size: 1.3rem;
    -webkit-mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-image: url("/static/images/ic-check-light.svg");
    mask-size: 1.3rem;
    mask-position: center center;
    mask-repeat: no-repeat;
    background: currentColor !important;
  }
}

@media (min-width: 460px) {
  .Filters__DropdownContent {
    min-width: 32.8rem;
  }
}

@media (min-width: 720px) {
  .Filters__Form {
    padding-top: 2rem;
    padding-bottom: 3.8rem;
  }

  .Filters__Dropdown {
    padding: 0.8rem 1.8rem;
    font-size: 1.8rem;
  }

  .Filters__Dropdown::after {
    width: 2.4rem;
    height: 2.4rem;
  }
}

/* ACTIVE FILTERS */

.Filters__ActiveFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
  padding-top: 2rem;
  padding-bottom: 1.6rem;
  border-top: 2px solid var(--gray);
}

.Filters__ActiveFilters:empty {
  display: none;
}

.Filters__ActiveFilter {
  display: flex;
  align-items: center;
  min-height: 4rem;
  padding: 0.4rem 1.8rem;
  border: 1px solid var(--gray);

  font-size: 1.6rem;
  font-weight: 400;
}

.Filters__ActiveFilter:hover {
  background-color: var(--gray);
}

.Filters__ActiveFilter__Remove {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  border: none;
  margin-right: 1rem;

  font-size: 0;
  background: transparent url("/static/images/ic-close-dark.svg") no-repeat center center;
  background-size: 1rem;
  cursor: pointer;
}

.Filters__ClearButton {
  border: none;

  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--gray-100);
  background: transparent;
  cursor: pointer;
}

@media (min-width: 720px) {
  .Filters__ActiveFilters {
    padding-top: 2.8rem;
    padding-bottom: 3.8rem;
  }
}


.Block {
  --block-padding-top: 3.2rem;
  --block-padding-bottom: 4.8rem;

  padding-top: var(--block-padding-top, 3.2rem);
  padding-bottom: var(--block-padding-bottom, 4.8rem);

  color: var(--block-text-color, var(--text-primary));
  background: center center no-repeat;
  background-size: cover;
  background-color: var(--block-background-color, var(--background-primary));
  scroll-margin-top: var(--site-header-size);
  background-image: var(--bg-mobile-image-url, none);
}

.Block--inverted {
  color: var(--text-inverted);
}

.Block--evenlyPadded {
  padding-bottom: 3.2rem;
}

.Block--backgroundSizeFit {
  background-size: contain;
}

.Block--backgroundSizeFill {
  background-size: cover;
}

.Block--backgroundPlacementTop {
  background-position: top;
}

.Block--backgroundPlacementBottom {
  background-position: bottom;
}

.Block h1,
.Block h2,
.Block h3,
.Block h4,
.Block h5,
.Block h6 {
  font-family: var(--font-primary);
}

.Block__Header {
  margin-bottom: 2rem;
}

.Block__Footer {
  margin-top: 2rem;
  text-align: center;
}

.Block__Title {
  margin: 0;

  font-family: var(--font-primary);
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  text-transform: initial;
}

.Block__Title--uppercase {
  text-transform: uppercase;
}

@media (min-width: 460px) {
  .Block {
    background-image: var(--bg-image-url, none);
  }
}

@media (min-width: 720px) {
  .Block {
    --block-padding-top: 6rem;
    --block-padding-bottom: 8.2rem;

    padding-top: var(--block-padding-top, 6rem);
    padding-bottom: var(--block-padding-bottom, 8.2rem);
  }

  .Block--evenlyPadded {
    padding-bottom: 6rem;
  }

  .Block__Header {
    margin-bottom: 3rem;
  }

  .Block__Footer {
    margin-top: 3rem;
  }

  .Block__Title {
    font-size: 4.8rem;
    line-height: initial;
  }
}

@media (min-width: 1024px) {
  .Block__Header {
    margin-bottom: 5.2rem;
  }

  .Block__Footer {
    margin-top: 5.6rem;
  }

  .Block__Title {
    font-size: 5.6rem;
  }
}

.Button,
.Button--small,
.Button--responsive.Button--small,
.Button--responsive.Button--medium,
.Button--responsive.Button--large {
  --button-size: 4.6rem;
  --button-text-size: 1.6rem;
  --button-text-weight: 700;
  --button-border-size: 3px;
  --button-padding-horizontal: 3rem;
  --button-padding-vertical: 1rem;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: var(--button-size, 4.6rem);
  padding: var(--button-padding-vertical, 1rem) var(--button-padding-horizontal, 3rem);
  border: var(--button-border-size, 3px) solid var(--button-border-color, var(--light));

  font-family: var(--font-primary);
  font-size: var(--button-text-size, 1.6rem);
  font-weight: var(--button-text-weight, 700);
  text-align: center;
  text-decoration: none;
  color: var(--button-text-color, var(--light));

  background-color: var(--button-background-color, transparent);
  cursor: pointer;
}

.Button--medium {
  --button-size: 5.4rem;
  --button-text-size: 2rem;
  --button-border-size: 5px;
}

.Button--large {
  --button-size: 6rem;
  --button-text-size: 2.4rem;
  --button-border-size: 5px;
}

@media (min-width: 720px) {
  .Button--responsive.Button--medium,
  .Button--responsive.Button--large {
    --button-size: 5.4rem;
    --button-text-size: 2rem;
    --button-border-size: 4px;
  }
}

@media (min-width: 1024px) {
  .Button--responsive.Button--large {
    --button-size: 6rem;
    --button-text-size: 2.4rem;
    --button-border-size: 5px;
  }
}

/* BUTTON STATES */

.Button {
  --button-text-color: var(--light);
  --button-border-color: var(--light);
  --button-background-color: transparent;
  --button-highlight-text-color: var(--dark);
  --button-highlight-border-color: var(--secondary-light);
  --button-highlight-background-color: var(--secondary-light);
  --button-disabled-opacity: 0.5;
}

.Button:disabled {
  opacity: var(--button-disabled-opacity, 0.5);
  cursor: not-allowed;
}

.Button:not(:disabled):hover,
.Button:not(:disabled):focus {
  --button-text-color: var(--button-highlight-text-color, var(--dark));
  --button-border-color: var(--button-highlight-border-color, var(--secondary-light));
  --button-background-color: var(--button-highlight-background-color, var(--secondary-light));
}

/* BUTTON THEMES */

.Button--secondary {
  --button-highlight-text-color: var(--dark);
  --button-highlight-border-color: var(--light);
  --button-highlight-background-color: var(--light);
}

.Button--dark {
  --button-text-color: var(--dark);
  --button-border-color: var(--dark);
}

.Button--dark.Button--secondary {
  --button-highlight-text-color: var(--light);
  --button-highlight-border-color: var(--dark);
  --button-highlight-background-color: var(--dark);
}

/* BUTTON VARIANTS */

.Button--icon {
  width: var(--button-size);
  padding: 0;

  background: center center no-repeat;
  background-size: calc(var(--button-size) / 3 * 2);
}

.Button.Button--icon {
  --button-text-size: 0;
  --button-border-size: 0;
}

.Button.Button--text {
  --button-border-color: transparent;
}

.Button.Button--full {
  display: flex;
}

.Carousel__Hidden {
  display: none !important;
}

.SearchableDropdown__Choice--hidden {
  display: none;
}
.Dropdown {
  position: relative;
}

.Dropdown__Content {
  position: absolute;
  top: 100%;
  right: 0;

  display: none;
  padding: 0.8rem 0;

  background-color: var(--background-primary);
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 4px 18px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 8px -2px;
}

.Dropdown--open .Dropdown__Content {
  display: block;
}

.Dropdown__Item {
  display: block;
  padding: 1rem 1.8rem;

  font-size: 1.4rem;
  text-align: right;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
}

.Dropdown__Item:hover,
.Dropdown__Item:focus {
  background-color: var(--light-gray);
}

/* DJANGO OVERRIDES */
/* Remove when django.css is migrated to new designs */

.Form ul.errorlist li {
  font-size: 1.2rem;
}

@media (min-width: 720px) {
  .Form ul.errorlist li {
    font-size: 1.6rem;
  }
}

/* FORMS */

.Form--custom {
  max-width: 82rem;
}

.Form__Heading {
  font-size: 1.4rem;
}

.Form__NonFieldErrors {
  margin-bottom: 2.8rem;
}

.Form__Separator {
  height: 2rem;
}

.FormField {
  --form-label-text-size: 1.6rem;
  --form-label-text-weight: 400;
  --form-label-text-color: var(--text-primary);
  --form-label-spacing: 1rem;
  --form-control-size: 4.6rem;
  --form-control-text-size: 1.6rem;
  --form-control-text-height: 2rem;
  --form-control-text-color: var(--gray-100);
  --form-control-border-size: 2px;
  --form-control-border-color: var(--gray);
  --form-control-vertical-padding-size: calc(
    (var(--form-control-size, 4.6rem)
      - var(--form-control-text-height, 2rem)
      - 2 * var(--form-control-border-size, 2px)
    ) / 2
  );
  --form-control-horizontal-padding-size: 1.6rem;
  --form-field-error-icon-size: 1.6rem;
  --form-field-vertical-spacing: 2.8rem;

  margin-bottom: var(--form-field-vertical-spacing, 2.8rem);
}

.FormField--required {
  --form-label-text-weight: 600;
}

.FormField--error {
  --form-label-text-color: var(--danger);
  --form-control-text-color: var(--danger);
  --form-control-border-color: var(--danger);
}

.FormField__Label {
  display: block;
  margin-bottom: var(--form-label-spacing, 1rem);

  font-family: var(--font-primary);
  font-size: var(--form-label-text-size, 1.6rem);
  font-weight: var(--form-label-text-weight, 400);
  text-transform: uppercase;
  color: var(--form-label-text-color, var(--text-primary));
}

.FormField--required .FormField__Label::after {
  content: "*";
}

.FormField__Control {
  display: block;
  width: 100%;
  padding: var(--form-control-vertical-padding-size) var(--form-control-horizontal-padding-size);
  border: 2px solid var(--form-control-border-color, var(--gray));

  font-size: var(--form-control-text-size, 1.6rem);
  line-height: var(--form-control-text-height, 2rem);
  color: var(--form-control-text-color, var(--gray-100));
}

textarea.FormField__Control {
  min-height: 20rem;
  padding-top: calc(var(--form-control-vertical-padding-size) * 2);
  padding-bottom: calc(var(--form-control-vertical-padding-size) * 2);

  resize: vertical;
}

.FormField:not(.FormField--required) textarea.FormField__Control {
  min-height: 5rem;
  padding: 0
}

.FormField__Link {
  display: inline-block;
  margin-top: 1.2rem;

  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  color: var(--primary-light);
}

.FormField__Link:hover,
.FormField__Link:focus {
  text-decoration: underline;
}

.FormField__Errors {
  margin-top: 1rem;
}

.FormField__Errors:empty {
  display: none;
}

.FormField__RadioWrapper {
  display: flex;
}

.FormField__Checkbox,
.FormField__Radio {
  -webkit-appearance: none;
  appearance: none;
  flex-shrink: 0;

  display: inline-flex;
  width: 2rem;
  height: 2rem;
  border: 2px solid var(--gray);
  margin: 0;
  margin-right: 1.6rem;

  color: var(--light);
  background-color: var(--light);
  cursor: pointer;
}

.FormField__Radio {
  border-radius: 50%;
}

.FormField__Checkbox:hover,
.FormField__Radio:hover {
  color: var(--gray);
  border-color: var(--block-text-color, var(--text-primary));
  background-color: var(--light);
}

.FormField__Checkbox:checked,
.FormField__Radio:checked {
  color: var(--light);
  border-color: var(--block-text-color, var(--text-primary));
  background: url("/static/images/ic-check-light.svg") center center no-repeat;
  background-color: var(--block-text-color, var(--text-primary));
}

.FormField__Choices {
  display: flex;
}

.FormField__Choices > * {
  margin-bottom: 1rem;
}

.FormField__Choice {
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  user-select: none;
  color: var(--block-text-color, var(--text-primary));
  cursor: pointer;
}

.FormField__Choice__Link {
  color: var(--primary-light);
}

.FormField__Consent {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
}

.FormField__Consent__Item {
  display: flex;
  padding-bottom: 1rem;
}

.FormField__Consent__Item .FormField__Choice {
  font-weight: 700;
}

.Form__Actions {
  display: flex;
  margin-top: 3.6rem;
}

.Form__Action--right {
  margin-left: auto;
}

.FormField__Disclaimer {
  margin-bottom: 3.6rem;
}

.Form__SubmitSuccess__Message--normal {
  font-weight: normal;
}

.FormField__Disclaimer .FormField__Disclaimer__Item {
  display: block;
  font-size: 0.67em;
  font-weight: bold;
  font-style: italic;
  color: var(--gray-100);
}

.FormField__Select {
  width: 100%;
  appearance: none;
  background: url("/static/images/ic-chevron-down-dark.svg") right center no-repeat;
}

@supports (mask-image: url("")) or (-webkit-mask-image: url("")) {
  .FormField__Checkbox::before,
  .FormField__Radio::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;

    -webkit-mask-image: url("/static/images/ic-check-light.svg");
    -webkit-mask-size: 1.3rem;
    -webkit-mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-image: url("/static/images/ic-check-light.svg");
    mask-size: 1.3rem;
    mask-position: center center;
    mask-repeat: no-repeat;
    background: currentColor !important;
  }
}

.FormField__DynamicRemove {
  position: relative;
  display: flex;
  align-items: center;
}

.FormField__DynamicRemove__Button {
  position: absolute;
  right: -2.2rem;
  width: 2rem;
  height: 2rem;
  margin-left: 1.2rem;
  border: none;
  font-size: 0;
  cursor: pointer;
  background: url("/static/images/ic-close-dark.svg") center center no-repeat;
}

.FormField__DynamicAdd {
  display: flex;
  align-items: center;

  padding-top: 3rem;
  border: none;

  font-size: 2rem;
  font-weight: 600;
  color: var(--primary-light);
  background-color: transparent;
}

.FormField__DynamicAdd::before {
  content: "";
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1.8rem;

  -webkit-mask-image: url("/static/images/ic-add-light.svg");
  mask-image: url("/static/images/ic-add-light.svg");
  background-color: var(--primary-light);
}

@media (min-width: 720px) {
  .Form__Heading {
    font-size: 2.6rem;
  }

  .FormField {
    --form-label-text-size: 2.4rem;
    --form-control-size: 5.4rem;
    --form-control-text-size: 2rem;
    --form-control-text-height: 2.8rem;
  }

  .FormField--error .FormField__ControlWrapper {
    position: relative;
  }

  .FormField--error .FormField__ControlWrapper::after {
    content: "";
    position: absolute;
    top: calc(50% - var(--form-field-error-icon-size, 1.6rem) / 2);
    left: calc(100% + 0.8rem);
    width: var(--form-field-error-icon-size, 1.6rem);
    height: var(--form-field-error-icon-size, 1.6rem);

    background: url("/static/images/ic-error.svg") center center no-repeat;
    background-size: 100%;
  }

  .FormField--required textarea.FormField__Control {
    min-height: 30rem;
  }

  .FormField__Link {
    font-size: 1.6rem;
  }

  .Form__Actions {
    margin-top: 6rem;
  }

  .Form__SubmitSuccess__Message {
    font-size: 3.2rem;
  }
}

@media (min-width: 1024px) {
  .FormField {
    --form-control-size: 6rem;
    --form-control-horizontal-padding-size: 2rem;
    --form-field-error-icon-size: 2rem;
  }
}

/* FORM FIELD SUFFIX */

.FormField__ControlWrapper--withSuffix {
  --form-control-vertical-padding-size: calc(
    (var(--form-control-size, 4.6rem)
      - var(--form-control-text-height, 2rem)
      - 2 * (var(--form-control-border-size, 2px) + 1px)
    ) / 2
  );

  display: flex;
  padding: 1px;
  border: var(--form-control-border-size, 2px) solid var(--form-control-border-color, var(--gray));
}

.FormField__Control--withSuffix {
  border: none;
}

.FormField__Suffix {
  --form-suffix-size: calc(
    var(--form-control-size, 4.6rem)
    - 2 * (var(--form-control-border-size, 2px) + 1px)
  );

  width: var(--form-suffix-size);
  height: var(--form-suffix-size);
  border: none;

  font-size: 0;
  background: transparent center center no-repeat;
  cursor: pointer;
}

.FormField__Suffix--showPassword {
  background-image: url("/static/images/ic-eye-dark.svg");
}

.FormField__Suffix--hidePassword {
  background-image: url("/static/images/ic-eye-close-dark.svg");
}

.Heading {
  font-family: var(--font-primary);
  line-height: 1.4;
}

h1.Heading,
.Heading--h1 {
  font-size: 3.4rem;
  font-weight: 700;
  letter-spacing: -0.01em;
}

h2.Heading,
.Heading--h2 {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: -0.01em;
}

h3.Heading,
.Heading--h3 {
  font-size: 1.6rem;
  font-weight: 600;
}

h4.Heading,
h5.Heading,
h6.Heading,
.Heading--h4,
.Heading--h5,
.Heading--h6 {
  font-size: 1.4rem;
  font-weight: 500;
}

@media (min-width: 720px) {
  .Heading {
    line-height: 1.22;
  }

  h1.Heading,
  .Heading--h1 {
    font-size: 6.2rem;
  }

  h2.Heading,
  .Heading--h2 {
    font-size: 5.6rem;
  }

  h3.Heading,
  .Heading--h3 {
    font-size: 2.6rem;
  }

  h4.Heading,
  h5.Heading,
  h6.Heading,
  .Heading--h4,
  .Heading--h5,
  .Heading--h6 {
    font-size: 2.4rem;
  }
}

/* HEADING PADDINGS */

.Heading--block {
  padding-top: var(--heading-padding-top);
  padding-bottom: var(--heading-padding-bottom);
  margin: 0;
}

h1.Heading.Heading--block,
h2.Heading.Heading--block,
.Heading--h1.Heading--block,
.Heading--h2.Heading--block {
  --heading-padding-top: 4.2rem;
  --heading-padding-bottom: 2rem;
}

h3.Heading.Heading--block,
.Heading--h3.Heading--block {
  --heading-padding-top: 1.4rem;
  --heading-padding-bottom: 1.4rem;
}

h4.Heading.Heading--block,
h5.Heading.Heading--block,
h6.Heading.Heading--block,
.Heading--h4.Heading--block,
.Heading--h5.Heading--block,
.Heading--h6.Heading--block  {
  --heading-padding-top: 1rem;
  --heading-padding-bottom: 1rem;
}

@media (min-width: 720px) {
  h1.Heading.Heading--block,
  h2.Heading.Heading--block,
  .Heading--h1.Heading--block,
  .Heading--h2.Heading--block {
    --heading-padding-top: 6.2rem;
    --heading-padding-bottom: 3.8rem;
  }
}

.Link {
  display: inline-block;

  font-size: 1.4rem;
  font-weight: 700;
  text-decoration: none;
  color: var(--text-primary);
}

.Link:hover,
.Link:focus {
  color: var(--text-primary-light);
}

.Link--inverted {
  color: var(--text-inverted);
}

.Link--underline {
  font-weight: 600;
}

.Link--underline::after {
  content: "";
  display: block;
  height: 0.3rem;

  background-color: var(--primary-light);
}

@media (min-width: 720px) {
  .Link {
    font-size: 1.8rem;
  }
}

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 25;

  display: grid;
  width: 100%;
  height: 100%;
  padding: 2rem 0;

  overflow: auto;
  pointer-events: none;
}

.Modal--open {
  pointer-events: initial;
}

.Modal__Sheet,
.Modal__Content {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
}

.Modal__Sheet {
  width: 100%;
  height: 100;
  margin: -2rem 0;

  background-color: var(--dark-transparent);
}

.Modal__Content {
  width: 100%;
  max-width: 64rem;
  margin: auto;

  color: var(--text-primary);
  background-color: var(--background-primary);
}

.Modal__Sheet,
.Modal__Content {
  opacity: 0;
}

.Modal--open .Modal__Sheet,
.Modal--open .Modal__Content {
  opacity: 1;
}

@media (min-width: 460px) {
  .Modal {
    padding: 2rem;
  }

  .Modal__Sheet {
    width: calc(100% + 4rem);
    margin: -2rem;
  }
}

/* TRANSITIONS */

.Modal__Sheet {
  transition: opacity 150ms ease;
}

.Modal__Content {
  transform: scale(0.75) translateY(25%);
  transition: opacity 200ms ease, transform 200ms ease;
}

.Modal--open .Modal__Content {
  transform: none;
  transition-delay: 100ms, 100ms;
}

@media (prefers-reduced-motion: reduce) {
  .Modal__Sheet,
  .Modal__Content {
    transition: none !important;
  }

  .Modal__Content {
    transform: none !important;
  }
}

.RichText {
  font-size: 1.6rem;
}

.RichText > *:first-child {
  margin-top: 0;
}

.RichText > *:last-child {
  margin-bottom: 0;
}

.RichText h1,
.RichText h2,
.RichText h3,
.RichText h4,
.RichText h5,
.RichText h6 {
  font-family: var(--font-primary);
  line-height: 1.44;
}

.RichText h1 {
  margin-top: 6.2rem;
  margin-bottom: 3.8rem;

  font-size: 3.4rem;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.RichText h2 {
  margin-top: 6.2rem;
  margin-bottom: 3.8rem;

  font-size: 3rem;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.RichText h3 {
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;

  font-size: 1.6rem;
  font-weight: 600;
}

.RichText h4,
.RichText h5,
.RichText h6 {
  margin-top: 1rem;
  margin-bottom: 1rem;

  font-size: 1.4rem;
  font-weight: 600;
}

.RichText p,
.RichText hr {
  margin-top: 2.2rem;
  margin-bottom: 2.2rem;
}

.RichText p:empty {
  display: none;
}

.RichText hr {
  border: 1px solid var(--gray);
}

.RichText img {
  display: block;
  max-width: 100%;
  height: auto;
}

.RichText img.full-width {
  width: 100%;
}

.RichText img.right {
  margin-left: auto;
}

.RichText img.center-small {
  margin-left: auto;
  margin-right: auto;
}

.RichText .text-center {
  text-align: center;
}

.RichText .text-right {
  text-align: right;
}

.RichText .EmbedWrapper {
  display: grid;
  aspect-ratio: 16 / 9;
}

.RichText .EmbedWrapper iframe {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.RichText--withPadding {
  padding: 1.8rem;
}

@media (min-width: 720px) {
  .RichText {
    font-size: 2rem;
    line-height: 1.22;
  }

  .RichText h1 {
    font-size: 6.2rem;
  }

  .RichText h2 {
    font-size: 5.6rem;
  }

  .RichText h3 {
    font-size: 2.6rem;
  }

  .RichText h4,
  .RichText h5,
  .RichText h6 {
    font-size: 2.4rem;
  }
}

/* ASPECT RATIO FALLBACK */

@supports not (aspect-ratio: 1) {
  .RichText .EmbedWrapper::before {
    padding-top: calc(100% / 16 * 9);
  }
}

.Switch {
  --switch-thumb-size: 1.6rem;
  --switch-track-padding: 0.3rem;
  --switch-track-size: calc(var(--switch-thumb-size) * 2);

  box-sizing: content-box;
  -webkit-appearance: none;
  appearance: none;

  width: var(--switch-track-size);
  height: var(--switch-thumb-size);
  padding: var(--switch-track-padding);
  border-radius: var(--switch-thumb-size);
  margin: 0;

  background-color: var(--light-gray);
  cursor: pointer;
  transition: background-color 150ms ease;
}

.Switch--responsive {
  --switch-thumb-size: 1.4rem;
}

.Switch:checked {
  background-color: var(--primary-light);
}

.Switch:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.Switch::before {
  content: "";
  display: block;
  width: var(--switch-thumb-size);
  height: var(--switch-thumb-size);
  border-radius: 50%;
  background-color: var(--background-primary);
  transition: all 150ms ease;
}

.Switch:checked::before {
  transform: translateX(100%);
}

@media (min-width: 720px) {
  .Switch--responsive {
    --switch-thumb-size: 1.6rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .Switch,
  .Switch::before {
    transition: none !important;
  }
}

.Tags {
  --tags-text-size: 1.8rem;
  --tags-text-color: var(--gray-100);
  --tags-background-color: var(--light-gray);
  --tags-padding-vertical: 0.8rem;
  --tags-padding-horizontal: 2.4rem;
  --tags-spacing: 1rem;

  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--tags-spacing, 1rem) / -2);
}

.Tags--withSeparator {
  --tags-spacing: 0.8rem;
}

.Tag {
  margin: calc(var(--tags-spacing, 1rem) / 2);
}

.Tag__Text {
  display: inline-block;
  padding: var(--tags-padding-vertical, 0.8rem) var(--tags-padding-horizontal);

  font-size: var(--tags-text-size);
  text-decoration: none;
  color: var(--tags-text-color, var(--gray-100));
  background-color: var(--tags-background-color, var(--light-gray));
}

.Tag--withSeparator {
  display: flex;
  align-items: center;
}

.Tag--withSeparator:nth-of-type(1):before {
  content: "\00B7";
  margin-left: 0.3rem;
  margin-right: 0.8rem;
  color: var(--gray-100);
}

.Tag--withSeparator:not(:last-child):after {
  content: "\00B7";
  margin-left: 0.8rem;
  color: var(--gray-100);
}

.ToastContainer {
  --margin-x: 2rem;
  --margin-y: 4rem;
  --offset-x: 0rem;

  position: fixed;
  right: calc(var(--margin-x) + var(--offset-x));
  bottom: var(--margin-y);

  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: calc(100% - (2 * var(--margin-x) + var(--offset-x)));
  max-width: calc(100% - (2 * var(--margin-x) + var(--offset-x)));

  z-index: 1;
}

.wagtail-userbar-reset ~ .ToastContainer {
  --margin-y: 2em;
  --offset-x: calc(3.5em + 2em);

  min-height: 3.5em;
}

.Toast {
  display: flex;
  align-items: center;
  min-height: 8rem;
  padding: 2rem 2.6rem;
  border: 2px solid var(--gray);
  border-radius: 0.8rem;

  background-color: var(--light);
  box-shadow: 1px 0px 1.6rem 0 var(--dark-transparent-500);
}

.Toast:not(:last-child) {
  margin-bottom: 0.8rem;
}

.Toast__Icon {
  flex-shrink: 0;
  display: block;
  width: 2.4rem;
  height: auto;
  margin-right: 1rem;
}

.Toast__Message {
  margin: 0;
  margin-right: auto;
  font-size: 1.4rem;
}

.Toast__Message a {
  text-decoration: none;
  color: var(--primary-light);
}

.Toast__Dismiss {
  flex-shrink: 0;
  display: block;
  width: 2rem;
  height: 2rem;
  border: none;
  margin-left: 1rem;

  background: transparent url("/static/images/ic-close-dark.svg") center center no-repeat;
  background-size: 1.2rem;
  cursor: pointer;
}

@media (min-width: 720px) {
  .ToastContainer {
    min-width: 53rem;
  }
}


.SiteBreadCrumbs {
  display: none;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}

.SiteBreadCrumbs__Links {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.6rem;
  padding: 0;
  margin: 0;

  list-style: none;
}

.SiteBreadCrumbs__Item {
  display: flex;
  align-items: center;
}

.SiteBreadCrumbs__Item--ellipsis {
  font-weight: 700;
  line-height: 1;
  overflow: visible;
}

.SiteBreadCrumbs__Item:not(:first-child)::before {
  content: "";
  flex-shrink: 0;
  display: block;
  width: 5px;
  height: 1rem;
  margin-right: 1.6rem;

  background: url("/static/images/ic-arrow-forward-dark.svg") center center no-repeat;
  background-size: 100% auto;
}

.SiteBreadCrumbs__Item:last-child {
  color: var(--primary-light);
}

.SiteBreadCrumbs__Link {
  display: block;
  max-width: 30rem;

  font-size: 1.6rem;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SiteBreadCrumbs__Link:hover,
.SiteBreadCrumbs__Link:focus {
  text-decoration: underline;
}

.SiteBreadCrumbs__Item:last-child .SiteBreadCrumbs__Link:hover {
  text-decoration: none;
}

.SiteBreadCrumbs__Dropdown {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.SiteBreadCrumbs__Dropdown:hover {
  text-decoration: underline;
}

.SiteBreadCrumbs__Dropdown__Content {
  z-index: 1;
  min-width: 30rem;
  left: 0;
  right: auto;
}

.SiteBreadCrumbs__Dropdown__Item {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  text-transform: none;
  white-space: normal;
}

@media (min-width: 720px) {
  .SiteBreadCrumbs {
    display: block;
  }
}

/* LAYOUT */

.SiteFooter__Details {
  row-gap: 3rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.SiteFooter__Details--hasMenu {
  padding-bottom: 3rem;
}

.SiteFooter__Logo,
.SiteFooter__Disclaimer,
.SiteFooter__Nav {
  grid-column: 1 / -1;
  align-self: start;
}

.SiteFooter__Logo {
  padding-bottom: 1rem;
}

.SiteFooter__Copyright {
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
}

@media (min-width: 720px) {
  .SiteFooter__Details {
    padding-top: 5.8rem;
    padding-bottom: 5.8rem;
  }

  .SiteFooter__Details--hasMenu {
    padding-bottom: 3rem;
  }

  .SiteFooter__Logo {
    grid-column: 1 / span 2;
    padding-bottom: 0;
  }

  .SiteFooter__Disclaimer {
    grid-column: 3 / span all;
    max-width: 70rem;
  }

  .SiteFooter__Copyright {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
}

/* CONTENT */

.SiteFooter {
  color: var(--footer-text-color, var(--text-inverted));
  background-color: var(--footer-background-color, var(--dark));
}

.SiteFooter__LogoImage {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 12rem;
  object-fit: cover;
  object-position: top center;
}

.SiteFooter__Heading {
  margin: 0;
  margin-bottom: 1.6rem;

  font-family: var(--font-primary);
  font-size: 1.8rem;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}

.SiteFooter__Text {
  margin: 0;

  font-size: 1.2rem;
  line-height: 1.55;
}

.SiteFooter__Text--small {
  font-size: 1rem;
  line-height: initial;
}

.SiteFooter__Nav {
  --site-footer-nav-items-spacing: 1.2rem;
  margin: calc(var(--site-footer-nav-items-spacing) / -2);
}

.SiteFooter__Nav__Link {
  display: inline-block;
  margin: calc(var(--site-footer-nav-items-spacing) / 2);

  font-size: 1.2rem;
  text-decoration: none;
}

.SiteFooter__Nav__Link:hover,
.SiteFooter__Nav__Link:focus {
  text-decoration: underline;
}

.SiteFooter__Separator {
  border: 1px solid currentColor;
  margin: 0;
}

.SiteFooter__Copyright__Separator {
  margin-left: 8px;
  margin-right: 8px;
}

.SiteFooter__Copyright__Link {
  font-weight: 700;
  text-decoration: none;
}

.SiteFooter__Copyright__Link:hover,
.SiteFooter__Copyright__Link:focus {
  text-decoration: underline;
}

@media (min-width: 720px) {
  .SiteFooter__LogoImage {
    max-height: 10rem;
  }

  .SiteFooter__Heading {
    font-size: 2.4rem;
  }

  .SiteFooter__Text {
    font-size: 1.6rem;
    line-height: 1.415;
  }

  .SiteFooter__Text--small {
    font-size: 1.4rem;
    line-height: initial;
  }

  .SiteFooter__Nav {
    --site-footer-nav-items-spacing: 2rem;
  }

  .SiteFooter__Nav__Link {
    font-size: 1.6rem;
  }
}

.Page,
.Page--headerDoubleRow {
  /* This variable is defined on the body/page element instead of scoping
   * just inside the header so that other page blocks and scripts can access
   * and make calculations based on this property.
   */
  --header-offset: 0;
  --header-scroll-progress: 0;
  --header-banner-height: 85;
  --site-header-size: 10rem;
}

@media (min-width: 720px) {
  .Page,
  .Page--headerDoubleRow {
    --site-header-size: 12rem;
  }
}

@media (min-width: 1024px) {
  .Page--headerDoubleRow {
    --site-header-size: 20rem;
  }
}

/* SITE HEADER */

.SiteHeader {
  position: sticky;
  top: 0;
  z-index: 20;

  font-family: var(--font-primary);
  background-color: var(--header-background-color, var(--background-primary));
  box-shadow: 0 1px 1px var(--gray);
  transition: box-shadow 250ms ease;
}

.SiteHeader--hasScrolled {
  box-shadow:
    0 1px 2px 0 var(--dark-transparent-200),
    0 2px 6px 2px var(--dark-transparent-300);
}

.SiteHeader__Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: var(--site-header-size);
}

.SiteLogo__Image {
  display: block;
  width: auto;
  max-width: 42rem;
  max-height: calc(var(--site-header-size) - 4.8rem);
  object-fit: contain;
  object-position: center center;
}

.SiteNav {
  /* Make sure the site nav content aligns with the edge of the wrapper.  */
  margin-right: -0.6rem;
}

.SiteNav__Toggle {
  background-image: url("/static/images/ic-menu-dark.svg");
}

.SiteNav--open .SiteNav__Toggle {
  position: relative;
  z-index: 21;

  background-image: url("/static/images/ic-close-light.svg");
}

@supports (mask-image: url("")) or (-webkit-mask-image: url("")) {
  .SiteNav__Toggle,
  .SiteNav--open .SiteNav__Toggle {
    display: flex;
    justify-content: center;
    align-items: center;

    background: none;
  }

  .SiteNav__Toggle::before {
    content: "";
    display: block;
    width: 3.2rem;
    height: 3.2rem;

    -webkit-mask-image: url("/static/images/ic-menu-dark.svg");
    mask-image: url("/static/images/ic-menu-dark.svg");
    background-color: var(--header-text-color, var(--primary-light));
  }

  .SiteNav--open .SiteNav__Toggle::before {
    -webkit-mask-image: url("/static/images/ic-close-light.svg");
    mask-image: url("/static/images/ic-close-light.svg");
    background-color: var(--header-text-color, var(--light));
  }
}

.SiteNav__Sheet {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  cursor: pointer;
  overflow: hidden;
  pointer-events: none;
}

.SiteNav--open .SiteNav__Sheet {
  pointer-events: initial;
}

.SiteNav__Links {
  display: flex;
  flex-direction: column;

  position: relative;
  width: 100%;
  height: 100%;
  padding: 8rem 2rem;

  color: var(--header-text-color, var(--text-inverted));
  background-color: var(--header-background-color, var(--primary-light));
  opacity: 0;
  overflow: auto;
  cursor: auto;
}

.SiteNav--open .SiteNav__Links {
  opacity: 1;
}

.SiteNav__MainLinks {
  display: flex;
  flex-direction: column;
}

.SiteNav__Link {
  padding: 1.1rem 1.2rem;

  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  text-decoration: none;
  text-transform: uppercase;
}

.SiteNav__Link--doubleRow {
  text-transform: capitalize;
  color: var(--secondary-light);
}

.SiteNav__Link__Text {
  position: relative;
}

.SiteNav__Link:hover .SiteNav__Link__Text::after,
.SiteNav__Link:focus .SiteNav__Link__Text::after,
.SiteNav__Link--current .SiteNav__Link__Text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-width: 3rem;
  height: 0.3rem;

  background-color: var(--header-accent-color, var(--secondary-light));
}

.SiteNav--open .SiteNav__OtherLinks {
  order: 1;
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

@media (min-width: 720px) {
  .SiteNav--open .SiteNav__Toggle {
    position: initial;
    background-image: url("/static/images/ic-menu-dark.svg");
  }

  @supports (mask-image: url("")) or (-webkit-mask-image: url("")) {
    .SiteNav--open .SiteNav__Toggle {
      background: none;
    }

    .SiteNav--open .SiteNav__Toggle::before {
      -webkit-mask-image: url("/static/images/ic-menu-dark.svg");
      mask-image: url("/static/images/ic-menu-dark.svg");
      background-color: var(--header-text-color, var(--primary-light));
    }
  }

  .SiteNav__Sheet {
    background: none;
  }

  .SiteNav--open .SiteNav__Sheet {
    background-color: var(--dark-transparent);
  }

  .SiteNav__Links {
    width: 36rem;
    opacity: 0;
  }

  .SiteNav--open .SiteNav__Links {
    opacity: 1;
  }
}

@media (min-width: 1024px) {
  .Page--headerDoubleRow .SiteHeader {
    --header-logo-offset-max: var(--header-banner-height);
    --header-logo-scale-min: 0.6;

    top: calc(var(--header-banner-height) * -1px + var(--header-offset) * 1px);

    font-family: var(--font-primary);
    background-color: var(--header-background-color, var(--background-primary));
    box-shadow: 0 1px 1px var(--gray);
    transition: box-shadow 250ms ease;
  }

  .SiteHeader--hasScrolled {
    box-shadow:
      0 1px 2px 0 var(--dark-transparent-200),
      0 2px 6px 2px var(--dark-transparent-300);
  }

  .SiteHeader__Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: var(--site-header-size);
  }

  .Page--headerDoubleRow .SiteLogo {
    margin-top: calc(
      (var(--header-logo-offset-max) * var(--header-scroll-progress) * 1px) /* set in JS based on page scroll */
      - (var(--header-offset) * 1px) /* take into account the vertical offset of entire banner */
    );
  }

  .Page--headerDoubleRow .SiteLogo__Image {
    display: block;
    width: auto;
    max-width: 42rem;
    max-height: calc(var(--site-header-size) - 4.8rem);
    object-fit: contain;
    object-position: center center;

    transform: scale(calc(
      1 - (1 - var(--header-logo-scale-min))
        * (var(--header-scroll-progress) /* set in JS based on page scroll */
        - (var(--header-offset) / var(--header-banner-height))) /* take into account the vertical offset of entire banner */
    ));
    transform-origin: left center;
  }

  .SiteNav {
    margin-right: -1.4rem;
  }

  .SiteNav__Toggle,
  .SiteNav--open .SiteNav__Toggle {
    display: none;
  }

  .SiteNav__Sheet,
  .SiteNav--open .SiteNav__Sheet {
    position: initial;
    display: block;

    background: none;
    pointer-events: initial;
    overflow: initial;
  }

  .SiteNav__Links {
    flex-direction: row;
    align-items: center;
    width: auto;
    padding: 0;

    color: var(--header-text-color, var(--text-primary));
    background-color: var(--header-background-color, transparent);
    opacity: 1;
    overflow: initial;
  }

  .SiteNav__Links--withOtherLinks {
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(2, auto);
    row-gap: 5.2rem
  }

  .SiteNav__MainLinks {
    flex-direction: row;
  }

  .SiteNav__OtherLinks,
  .SiteNav--open .SiteNav__OtherLinks {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
  }

  .SiteNav__OtherLinks .SiteNav__Link:first-child {
    margin: 0;
  }

  .SiteNav__OtherLinks .SiteNav__Link:last-child {
    margin-right: 2.2rem;
  }

  .SiteNav__OtherLinks .Dropdown__Item.SiteNav__Link:last-child {
    margin-right: 0;
  }

  .SiteNav__Link {
    font-size: 1.8rem;
    text-align: center;
  }

  .SiteNav__Link:not(:last-child) {
    margin-right: 3.2rem;
    margin-bottom: 0;
  }

  .SiteNav__Link__Text {
    display: inline-block;
  }

  .SiteNav__Link--current .SiteNav__Link__Text {
    color: var(--header-accent-color, var(--secondary-light));
  }

  .SiteNav__Link:hover .SiteNav__Link__Text::after,
  .SiteNav__Link:focus .SiteNav__Link__Text::after,
  .SiteNav__Link--current .SiteNav__Link__Text::after {
    content: "";
    position: absolute;
    top: calc(6px + var(--site-header-size) / 2);
    left: 50%;
    right: 0;
    width: 100%;
    max-width: none;
    height: 0.8rem;

    background-color: var(--header-accent-color, var(--secondary-light));
    transform: translate(-50%);
  }

  .Page--headerDoubleRow .SiteNav__Link:hover .SiteNav__Link__Text::after,
  .Page--headerDoubleRow .SiteNav__Link:focus .SiteNav__Link__Text::after,
  .Page--headerDoubleRow .SiteNav__Link--current .SiteNav__Link__Text::after {
    top: calc(5px + var(--site-header-size) / 4);
  }
}

/* CURRENT USER DROPDOWN */

@media (max-width: 1023px) {
  .SiteNav__CurrentUser {
    padding: 0;
    border: none;
    margin: 0 -2rem;
    margin-top: auto;

    background: none;
  }

  .SiteNav__CurrentUser::before {
    display: flex;
    content: "";
    margin: 2.8rem 3.2rem;
    border-top: 0.1rem solid var(--light);
  }

  .SiteNav__CurrentUser__Name {
    display: block;
    padding: 1.1rem 3.2rem;

    font-size: 2rem;
    font-weight: 600;
    text-align: left;
  }

  .SiteNav__CurrentUser .Dropdown__Content,
  .SiteNav__CurrentUser.Dropdown--open .Dropdown__Content {
    position: initial;
    display: block;
    padding: 0 2rem;

    background: none;
    box-shadow: none;
  }

  .SiteNav__CurrentUser .Dropdown__Item {
    padding: 1.1rem 1.2rem;

    font-size: 1.8rem;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
    text-transform: none;
    background: none;
  }

  .SiteNav__CurrentUser .Dropdown__Item__Text {
    position: relative;
  }

  .SiteNav__CurrentUser .SiteNav__Link:hover .Dropdown__Item__Text::after,
  .SiteNav__CurrentUser .SiteNav__Link:focus .Dropdown__Item__Text::after,
  .SiteNav__CurrentUser .SiteNav__Link--current .Dropdown__Item__Text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-width: 3rem;
    height: 0.3rem;

    background-color: var(--header-accent-color, var(--secondary-light));
  }
}

@media (min-width: 1024px) {
  .SiteNav__CurrentUser {
    display: flex;
    align-items: center;
    padding: 1.1rem 1.2rem;
    border: none;

    background: none;
    cursor: pointer;
  }

  .SiteNav__CurrentUser::after {
    content: "";
    width: 2.4rem;
    height: 2.4rem;
    margin-left: 0.4rem;
    margin-right: -0.4rem;

    background: url("/static/images/ic-chevron-down-light.svg") center center no-repeat;
  }

  @supports (mask-image: url("")) or (-webkit-mask-image: url("")) {
    .SiteNav__CurrentUser::after {
      -webkit-mask-image: url("/static/images/ic-chevron-down-light.svg");
      mask-image: url("/static/images/ic-chevron-down-light.svg");
      background: none;
      background-color: var(--header-text-color, var(--text-primary));
    }
  }

  .SiteNav__CurrentUser__Name {
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: -0.01em;
  }

  .SiteNav__CurrentUser:hover .SiteNav__CurrentUser__Name,
  .SiteNav__CurrentUser:focus .SiteNav__CurrentUser__Name{
    color: var(--header-accent-color, var(--secondary-light));
  }

  .SiteNav__CurrentUser .Dropdown__Content {
    min-width: 18rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .SiteNav__OtherLinks .SiteNav__CurrentUser .Dropdown__Content,
  .SiteNav__OtherLinks .SiteNav__CurrentUser.Dropdown--open .Dropdown__Content {
    z-index: 1;
    min-width: 20rem;
    padding: 1.2rem 0.8rem;
    border-radius: 0.6rem;
  }

  .SiteNav__CurrentUser.Dropdown--open::after {
    transform: rotate(180deg);
  }

  .SiteNav__CurrentUser .Dropdown__Item {
    padding: 1rem 1.8rem;
    margin: 0;

    font-size: 1.6rem;
    font-weight: 400;
    text-decoration: none;
    text-transform: none;
    text-align: left;
    color: var(--text-primary);
  }

  .SiteNav__OtherLinks .SiteNav__CurrentUser .Dropdown__Item,
  .SiteNav__OtherLinks .SiteNav__CurrentUser .Dropdown__Item {
    padding: 1.2rem 2.2rem;
    border-radius: 0.6rem;
  }

  .SiteNav__OtherLinks .SiteNav__CurrentUser .Dropdown__Item:hover,
  .SiteNav__OtherLinks .SiteNav__CurrentUser .Dropdown__Item:focus {
    color: var(--light);
    background-color: var(--secondary-light);
  }
}

/* BACK LINK */

.SiteNav__BackLink {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.SiteNav__BackLink--userIsAuthenticated {
  margin-top: 0;
}

.SiteNav__BackLink::before {
  content: "";
  display: block;
  border-top: 0.1rem solid var(--light);
  margin: 2.8rem 0;
}

.SiteNav__Link--backLink {
  font-size: 1.8rem;
  font-weight: 400;
  text-transform: none;
}

.SiteNav__Link--backLink:hover {
  color: var(--header-accent-color, var(--secondary-light));
}

.SiteNav__Link--backLinkWithOtherLinks {
  font-size: 1.6rem;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .SiteNav__Link--backLinkWithOtherLinks {
    padding: 0.7rem 2.1rem;
    border-radius: 4rem;
    background: rgba(255, 255, 255, 0.2);
  }

  .SiteNav__BackLink {
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .SiteNav__BackLink::before {
    height: 2rem;
    border: 0;
    border-left: 0.1rem solid var(--light);
    margin: 0 1.8rem;
  }

  .SiteNav__BackLink--withOtherLinks::before {
    height: 0;
  }
}

/* TRANSITIONS */

.SiteNav__Sheet::before {
  content: "";
  position: absolute;
  top: 2.2rem;
  right: 1.4rem;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;

  background-color: var(--header-background-color, var(--primary-light));
  opacity: 0;

  transform-origin: center center;
  transition: transform 300ms ease, opacity 100ms ease;
}

.SiteNav--open .SiteNav__Sheet::before {
  opacity: 1;
  transform: scale(100);
  transition-duration: 500ms, 300ms;
}

.SiteNav__Links {
  transform: translateY(2rem);
}

.SiteNav--open .SiteNav__Links {
  transform: translateY(0);
  transition: opacity 250ms ease, transform 250ms ease;
  transition-delay: 200ms, 180ms;
}

@media (min-width: 720px) {
  .SiteNav__Sheet::before {
    display: none;
  }

  .SiteNav--open .SiteNav__Sheet {
    transition: background-color 150ms ease;
  }

  .SiteNav__Links {
    transform: translateX(-100%);
    transition: transform 150ms ease;
  }

  .SiteNav--open .SiteNav__Links {
    transform: translateX(0);
    transition: transform 150ms ease;
  }
}

@media (min-width: 1024px) {
  .SiteNav__Links {
    transform: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .SiteNav__Sheet::before,
  .SiteNav__Sheet,
  .SiteNav__Links {
    transition: none !important;
  }
}

.SiteMessages {
  padding: 0;
  margin: 0;

  list-style: none;
}

.Wrapper--siteMessages {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.Wrapper--siteMessages::before {
  content: "";
  flex-shrink: 0;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  margin-right: 1.6rem;

  background-color: var(--secondary);
}

.SiteMessages__Item {
  font-size: 1.4rem;
  color: var(--text-inverted);
  background-color: var(--primary-light);
}

.SiteMessages__Item:not(:last-child) {
  border-bottom: 1px solid var(--primary);
}

@media (min-width: 720px) {
  .Wrapper--siteMessages::before {
    width: 0.8rem;
    height: 0.8rem;
  }

  .SiteMessages__Item {
    font-size: 1.8rem;
  }
}


/* Imports from other Django apps */
.Modal__Content--cookieConsentModal {
  padding: 4.2rem;
}

.CookieConsentModal__Title {
  margin: 0;
  margin-bottom: 2rem;

  font-family: var(--font-primary);
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}

.CookieConsentModal__Text {
  margin: 0;

  font-size: 1.4rem;
  line-height: 1.41;
}

.CookieConsentModal__Cookies {
  padding: 0;
  margin: 0;
  margin-top: 3.2rem;

  list-style: none;
  font-size: 1.4rem;
  font-weight: 600;
}

.CookieConsentModal__Cookie__Label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0;
  margin: 0;

  font-family: var(--font-primary);
}

.CookieConsentModal__Actions {
  margin-top: 5.2rem;
  text-align: center;
}

.CookieConsentModal__Action--save {
  font-size: 1.4rem !important;
}

@media (min-width: 720px) {
  .Modal__Content--cookieConsentModal {
    padding: 5.8rem;
    padding-bottom: 4.2rem;
  }

  .CookieConsentModal__Title {
    font-size: 2.6rem;
  }

  .CookieConsentModal__Text {
    font-size: 1.6rem;
    line-height: 1.3;
    letter-spacing: 0.05em;
  }

  .CookieConsentModal__Cookies {
    font-size: 1.6rem;
  }

  .CookieConsentModal__Action--save {
    font-size: 1.6rem !important;
  }
}


.Wrapper--cookieConsentBanner {
  display: flex;
  flex-wrap: wrap;
  padding-left: 4.2rem;
  padding-right: 4.2rem;
}

.CookieConsentBanner {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 20;

  display:  none;
  width: 100%;
  padding-top: 4.2rem;
  padding-bottom: 4.2rem;

  color: var(--text-inverted);
  background-color: var(--primary-light);
}

.CookieConsentBanner--show {
  display: block;
}

.CookieConsentBanner__Title {
  margin: 0;
  margin-bottom: 1.2rem;

  font-family: var(--font-primary);
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -0.01em;
}

.CookieConsentBanner__Text {
  margin: 0;

  font-size: 1.4rem;
  line-height: 1.41;
}

.CookieConsentBanner__Actions {
  flex-shrink: 0;

  margin-top: 5.6rem;
  margin-left: auto;
  margin-right: 2rem;
}

.CookieConsentBanner__Action {
  font-family: var(--font-primary);
}

@media (min-width: 720px) {
  .Wrapper--cookieConsentBanner {
    flex-wrap: nowrap;
  }

  .CookieConsentBanner {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }

  .CookieConsentBanner__Details {
    max-width: 82rem;
    margin-right: 3rem;
  }

  .CookieConsentBanner__Title {
    font-size: 2rem;
  }

  .CookieConsentBanner__Text {
    font-size: 1.6rem;
    line-height: 1.3;
  }

  .CookieConsentBanner__Actions {
    margin-top: auto;
  }
}

