.Page {
  display: flex;
  flex-direction: column;
}

.SiteHeader,
.SiteFooter {
  flex-shrink: 0;
}

.SiteContent {
  flex-grow: 1;
}
