.Switch {
  --switch-thumb-size: 1.6rem;
  --switch-track-padding: 0.3rem;
  --switch-track-size: calc(var(--switch-thumb-size) * 2);

  box-sizing: content-box;
  -webkit-appearance: none;
  appearance: none;

  width: var(--switch-track-size);
  height: var(--switch-thumb-size);
  padding: var(--switch-track-padding);
  border-radius: var(--switch-thumb-size);
  margin: 0;

  background-color: var(--light-gray);
  cursor: pointer;
  transition: background-color 150ms ease;
}

.Switch--responsive {
  --switch-thumb-size: 1.4rem;
}

.Switch:checked {
  background-color: var(--primary-light);
}

.Switch:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.Switch::before {
  content: "";
  display: block;
  width: var(--switch-thumb-size);
  height: var(--switch-thumb-size);
  border-radius: 50%;
  background-color: var(--background-primary);
  transition: all 150ms ease;
}

.Switch:checked::before {
  transform: translateX(100%);
}

@media (min-width: 720px) {
  .Switch--responsive {
    --switch-thumb-size: 1.6rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .Switch,
  .Switch::before {
    transition: none !important;
  }
}
