/* DJANGO OVERRIDES */
/* Remove when django.css is migrated to new designs */

.Form ul.errorlist li {
  font-size: 1.2rem;
}

@media (min-width: 720px) {
  .Form ul.errorlist li {
    font-size: 1.6rem;
  }
}

/* FORMS */

.Form--custom {
  max-width: 82rem;
}

.Form__Heading {
  font-size: 1.4rem;
}

.Form__NonFieldErrors {
  margin-bottom: 2.8rem;
}

.Form__Separator {
  height: 2rem;
}

.FormField {
  --form-label-text-size: 1.6rem;
  --form-label-text-weight: 400;
  --form-label-text-color: var(--text-primary);
  --form-label-spacing: 1rem;
  --form-control-size: 4.6rem;
  --form-control-text-size: 1.6rem;
  --form-control-text-height: 2rem;
  --form-control-text-color: var(--gray-100);
  --form-control-border-size: 2px;
  --form-control-border-color: var(--gray);
  --form-control-vertical-padding-size: calc(
    (var(--form-control-size, 4.6rem)
      - var(--form-control-text-height, 2rem)
      - 2 * var(--form-control-border-size, 2px)
    ) / 2
  );
  --form-control-horizontal-padding-size: 1.6rem;
  --form-field-error-icon-size: 1.6rem;
  --form-field-vertical-spacing: 2.8rem;

  margin-bottom: var(--form-field-vertical-spacing, 2.8rem);
}

.FormField--required {
  --form-label-text-weight: 600;
}

.FormField--error {
  --form-label-text-color: var(--danger);
  --form-control-text-color: var(--danger);
  --form-control-border-color: var(--danger);
}

.FormField__Label {
  display: block;
  margin-bottom: var(--form-label-spacing, 1rem);

  font-family: var(--font-primary);
  font-size: var(--form-label-text-size, 1.6rem);
  font-weight: var(--form-label-text-weight, 400);
  text-transform: uppercase;
  color: var(--form-label-text-color, var(--text-primary));
}

.FormField--required .FormField__Label::after {
  content: "*";
}

.FormField__Control {
  display: block;
  width: 100%;
  padding: var(--form-control-vertical-padding-size) var(--form-control-horizontal-padding-size);
  border: 2px solid var(--form-control-border-color, var(--gray));

  font-size: var(--form-control-text-size, 1.6rem);
  line-height: var(--form-control-text-height, 2rem);
  color: var(--form-control-text-color, var(--gray-100));
}

textarea.FormField__Control {
  min-height: 20rem;
  padding-top: calc(var(--form-control-vertical-padding-size) * 2);
  padding-bottom: calc(var(--form-control-vertical-padding-size) * 2);

  resize: vertical;
}

.FormField:not(.FormField--required) textarea.FormField__Control {
  min-height: 5rem;
  padding: 0
}

.FormField__Link {
  display: inline-block;
  margin-top: 1.2rem;

  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  color: var(--primary-light);
}

.FormField__Link:hover,
.FormField__Link:focus {
  text-decoration: underline;
}

.FormField__Errors {
  margin-top: 1rem;
}

.FormField__Errors:empty {
  display: none;
}

.FormField__RadioWrapper {
  display: flex;
}

.FormField__Checkbox,
.FormField__Radio {
  -webkit-appearance: none;
  appearance: none;
  flex-shrink: 0;

  display: inline-flex;
  width: 2rem;
  height: 2rem;
  border: 2px solid var(--gray);
  margin: 0;
  margin-right: 1.6rem;

  color: var(--light);
  background-color: var(--light);
  cursor: pointer;
}

.FormField__Radio {
  border-radius: 50%;
}

.FormField__Checkbox:hover,
.FormField__Radio:hover {
  color: var(--gray);
  border-color: var(--block-text-color, var(--text-primary));
  background-color: var(--light);
}

.FormField__Checkbox:checked,
.FormField__Radio:checked {
  color: var(--light);
  border-color: var(--block-text-color, var(--text-primary));
  background: url("/static/images/ic-check-light.svg") center center no-repeat;
  background-color: var(--block-text-color, var(--text-primary));
}

.FormField__Choices {
  display: flex;
}

.FormField__Choices > * {
  margin-bottom: 1rem;
}

.FormField__Choice {
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  user-select: none;
  color: var(--block-text-color, var(--text-primary));
  cursor: pointer;
}

.FormField__Choice__Link {
  color: var(--primary-light);
}

.FormField__Consent {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
}

.FormField__Consent__Item {
  display: flex;
  padding-bottom: 1rem;
}

.FormField__Consent__Item .FormField__Choice {
  font-weight: 700;
}

.Form__Actions {
  display: flex;
  margin-top: 3.6rem;
}

.Form__Action--right {
  margin-left: auto;
}

.FormField__Disclaimer {
  margin-bottom: 3.6rem;
}

.Form__SubmitSuccess__Message--normal {
  font-weight: normal;
}

.FormField__Disclaimer .FormField__Disclaimer__Item {
  display: block;
  font-size: 0.67em;
  font-weight: bold;
  font-style: italic;
  color: var(--gray-100);
}

.FormField__Select {
  width: 100%;
  appearance: none;
  background: url("/static/images/ic-chevron-down-dark.svg") right center no-repeat;
}

@supports (mask-image: url("")) or (-webkit-mask-image: url("")) {
  .FormField__Checkbox::before,
  .FormField__Radio::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;

    -webkit-mask-image: url("/static/images/ic-check-light.svg");
    -webkit-mask-size: 1.3rem;
    -webkit-mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-image: url("/static/images/ic-check-light.svg");
    mask-size: 1.3rem;
    mask-position: center center;
    mask-repeat: no-repeat;
    background: currentColor !important;
  }
}

.FormField__DynamicRemove {
  position: relative;
  display: flex;
  align-items: center;
}

.FormField__DynamicRemove__Button {
  position: absolute;
  right: -2.2rem;
  width: 2rem;
  height: 2rem;
  margin-left: 1.2rem;
  border: none;
  font-size: 0;
  cursor: pointer;
  background: url("/static/images/ic-close-dark.svg") center center no-repeat;
}

.FormField__DynamicAdd {
  display: flex;
  align-items: center;

  padding-top: 3rem;
  border: none;

  font-size: 2rem;
  font-weight: 600;
  color: var(--primary-light);
  background-color: transparent;
}

.FormField__DynamicAdd::before {
  content: "";
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1.8rem;

  -webkit-mask-image: url("/static/images/ic-add-light.svg");
  mask-image: url("/static/images/ic-add-light.svg");
  background-color: var(--primary-light);
}

@media (min-width: 720px) {
  .Form__Heading {
    font-size: 2.6rem;
  }

  .FormField {
    --form-label-text-size: 2.4rem;
    --form-control-size: 5.4rem;
    --form-control-text-size: 2rem;
    --form-control-text-height: 2.8rem;
  }

  .FormField--error .FormField__ControlWrapper {
    position: relative;
  }

  .FormField--error .FormField__ControlWrapper::after {
    content: "";
    position: absolute;
    top: calc(50% - var(--form-field-error-icon-size, 1.6rem) / 2);
    left: calc(100% + 0.8rem);
    width: var(--form-field-error-icon-size, 1.6rem);
    height: var(--form-field-error-icon-size, 1.6rem);

    background: url("/static/images/ic-error.svg") center center no-repeat;
    background-size: 100%;
  }

  .FormField--required textarea.FormField__Control {
    min-height: 30rem;
  }

  .FormField__Link {
    font-size: 1.6rem;
  }

  .Form__Actions {
    margin-top: 6rem;
  }

  .Form__SubmitSuccess__Message {
    font-size: 3.2rem;
  }
}

@media (min-width: 1024px) {
  .FormField {
    --form-control-size: 6rem;
    --form-control-horizontal-padding-size: 2rem;
    --form-field-error-icon-size: 2rem;
  }
}

/* FORM FIELD SUFFIX */

.FormField__ControlWrapper--withSuffix {
  --form-control-vertical-padding-size: calc(
    (var(--form-control-size, 4.6rem)
      - var(--form-control-text-height, 2rem)
      - 2 * (var(--form-control-border-size, 2px) + 1px)
    ) / 2
  );

  display: flex;
  padding: 1px;
  border: var(--form-control-border-size, 2px) solid var(--form-control-border-color, var(--gray));
}

.FormField__Control--withSuffix {
  border: none;
}

.FormField__Suffix {
  --form-suffix-size: calc(
    var(--form-control-size, 4.6rem)
    - 2 * (var(--form-control-border-size, 2px) + 1px)
  );

  width: var(--form-suffix-size);
  height: var(--form-suffix-size);
  border: none;

  font-size: 0;
  background: transparent center center no-repeat;
  cursor: pointer;
}

.FormField__Suffix--showPassword {
  background-image: url("/static/images/ic-eye-dark.svg");
}

.FormField__Suffix--hidePassword {
  background-image: url("/static/images/ic-eye-close-dark.svg");
}
