.Modal__Content--cookieConsentModal {
  padding: 4.2rem;
}

.CookieConsentModal__Title {
  margin: 0;
  margin-bottom: 2rem;

  font-family: var(--font-primary);
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}

.CookieConsentModal__Text {
  margin: 0;

  font-size: 1.4rem;
  line-height: 1.41;
}

.CookieConsentModal__Cookies {
  padding: 0;
  margin: 0;
  margin-top: 3.2rem;

  list-style: none;
  font-size: 1.4rem;
  font-weight: 600;
}

.CookieConsentModal__Cookie__Label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0;
  margin: 0;

  font-family: var(--font-primary);
}

.CookieConsentModal__Actions {
  margin-top: 5.2rem;
  text-align: center;
}

.CookieConsentModal__Action--save {
  font-size: 1.4rem !important;
}

@media (min-width: 720px) {
  .Modal__Content--cookieConsentModal {
    padding: 5.8rem;
    padding-bottom: 4.2rem;
  }

  .CookieConsentModal__Title {
    font-size: 2.6rem;
  }

  .CookieConsentModal__Text {
    font-size: 1.6rem;
    line-height: 1.3;
    letter-spacing: 0.05em;
  }

  .CookieConsentModal__Cookies {
    font-size: 1.6rem;
  }

  .CookieConsentModal__Action--save {
    font-size: 1.6rem !important;
  }
}


.Wrapper--cookieConsentBanner {
  display: flex;
  flex-wrap: wrap;
  padding-left: 4.2rem;
  padding-right: 4.2rem;
}

.CookieConsentBanner {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 20;

  display:  none;
  width: 100%;
  padding-top: 4.2rem;
  padding-bottom: 4.2rem;

  color: var(--text-inverted);
  background-color: var(--primary-light);
}

.CookieConsentBanner--show {
  display: block;
}

.CookieConsentBanner__Title {
  margin: 0;
  margin-bottom: 1.2rem;

  font-family: var(--font-primary);
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -0.01em;
}

.CookieConsentBanner__Text {
  margin: 0;

  font-size: 1.4rem;
  line-height: 1.41;
}

.CookieConsentBanner__Actions {
  flex-shrink: 0;

  margin-top: 5.6rem;
  margin-left: auto;
  margin-right: 2rem;
}

.CookieConsentBanner__Action {
  font-family: var(--font-primary);
}

@media (min-width: 720px) {
  .Wrapper--cookieConsentBanner {
    flex-wrap: nowrap;
  }

  .CookieConsentBanner {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }

  .CookieConsentBanner__Details {
    max-width: 82rem;
    margin-right: 3rem;
  }

  .CookieConsentBanner__Title {
    font-size: 2rem;
  }

  .CookieConsentBanner__Text {
    font-size: 1.6rem;
    line-height: 1.3;
  }

  .CookieConsentBanner__Actions {
    margin-top: auto;
  }
}
