/* FILTERS DROPDOWNS */

.Filters__Form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 0;
  padding-bottom: 2.8rem;
}

.Filters__Dropdowns {
  display: flex;
  flex-wrap: wrap;
  margin-right: 3px;
  margin-bottom: 3px;
}

.Filters__Dropdown {
  display: flex;
  align-items: center;
  padding: 0.85rem 2.2rem;
  border: 3px solid var(--block-text-color, var(--text-primary));
  margin: 0;
  margin-right: -3px;
  margin-bottom: -3px;

  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  background: var(--light);
  cursor: pointer;
}

.Filters__Dropdown:hover,
.Filters__Dropdown.Dropdown--open {
  color: var(--light);
  background: var(--block-text-color, var(--text-primary));
}

.Filters__Dropdown::after {
  content: "";
  width: 2.2rem;
  height: 2.2rem;

  background: url("/static/images/ic-chevron-down-light.svg") center center no-repeat;
}

@supports (mask-image: url("")) or (-webkit-mask-image: url("")) {
  .Filters__Dropdown::after {
    -webkit-mask-image: url("/static/images/ic-chevron-down-light.svg");
    mask-image: url("/static/images/ic-chevron-down-light.svg");
    background: none;
    background-color: currentColor;
  }
}

.Filters__DropdownContent {
  min-width: calc(100vw - 6rem);
  max-height: calc(100vh / 3);
  padding: 1.8rem 2.2rem !important;
  border: 3px solid var(--block-text-color, var(--text-primary));
  z-index: 1;
  box-shadow: 0.2rem 1.4rem 2rem var(--dark-transparent-400);
  overflow-y: scroll;
}

.Filters__Choice {
  display: flex;
  justify-content: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;

  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: left;
  text-transform: none;
  user-select: none;
  color: var(--block-text-color, var(--text-primary));
  cursor: pointer;
}

.Filters__Checkbox {
  -webkit-appearance: none;
  appearance: none;
  flex-shrink: 0;

  display: flex;
  width: 2rem;
  height: 2rem;
  border: 2px solid var(--gray);
  margin: 0;
  margin-right: 1.6rem;

  color: var(--light);
  background-color: var(--light);
  cursor: pointer;
}

.Filters__Choice:hover .Filters__Checkbox {
  color: var(--gray);
  border-color: var(--block-text-color, var(--text-primary));
  background-color: var(--light);
}

.Filters__Choice .Filters__Checkbox:checked {
  color: var(--light);
  border-color: var(--block-text-color, var(--text-primary));
  background: url("/static/images/ic-check-light.svg") center center no-repeat;
  background-color: var(--block-text-color, var(--text-primary));
}

@supports (mask-image: url("")) or (-webkit-mask-image: url("")) {
  .Filters__Checkbox::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;

    -webkit-mask-image: url("/static/images/ic-check-light.svg");
    -webkit-mask-size: 1.3rem;
    -webkit-mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-image: url("/static/images/ic-check-light.svg");
    mask-size: 1.3rem;
    mask-position: center center;
    mask-repeat: no-repeat;
    background: currentColor !important;
  }
}

@media (min-width: 460px) {
  .Filters__DropdownContent {
    min-width: 32.8rem;
  }
}

@media (min-width: 720px) {
  .Filters__Form {
    padding-top: 2rem;
    padding-bottom: 3.8rem;
  }

  .Filters__Dropdown {
    padding: 0.8rem 1.8rem;
    font-size: 1.8rem;
  }

  .Filters__Dropdown::after {
    width: 2.4rem;
    height: 2.4rem;
  }
}

/* ACTIVE FILTERS */

.Filters__ActiveFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
  padding-top: 2rem;
  padding-bottom: 1.6rem;
  border-top: 2px solid var(--gray);
}

.Filters__ActiveFilters:empty {
  display: none;
}

.Filters__ActiveFilter {
  display: flex;
  align-items: center;
  min-height: 4rem;
  padding: 0.4rem 1.8rem;
  border: 1px solid var(--gray);

  font-size: 1.6rem;
  font-weight: 400;
}

.Filters__ActiveFilter:hover {
  background-color: var(--gray);
}

.Filters__ActiveFilter__Remove {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  border: none;
  margin-right: 1rem;

  font-size: 0;
  background: transparent url("/static/images/ic-close-dark.svg") no-repeat center center;
  background-size: 1rem;
  cursor: pointer;
}

.Filters__ClearButton {
  border: none;

  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--gray-100);
  background: transparent;
  cursor: pointer;
}

@media (min-width: 720px) {
  .Filters__ActiveFilters {
    padding-top: 2.8rem;
    padding-bottom: 3.8rem;
  }
}
