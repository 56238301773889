.Link {
  display: inline-block;

  font-size: 1.4rem;
  font-weight: 700;
  text-decoration: none;
  color: var(--text-primary);
}

.Link:hover,
.Link:focus {
  color: var(--text-primary-light);
}

.Link--inverted {
  color: var(--text-inverted);
}

.Link--underline {
  font-weight: 600;
}

.Link--underline::after {
  content: "";
  display: block;
  height: 0.3rem;

  background-color: var(--primary-light);
}

@media (min-width: 720px) {
  .Link {
    font-size: 1.8rem;
  }
}
