.Grid {
  display: grid;
  gap: 2rem;
}

.Col--sm0  { grid-column: 1 / -1;  }
.Col--sm1  { grid-column: auto / span 1;  }
.Col--sm2  { grid-column: auto / span 2;  }
.Col--sm3  { grid-column: auto / span 3;  }
.Col--sm4  { grid-column: auto / span 4;  }
.Col--sm5  { grid-column: auto / span 5;  }
.Col--sm6  { grid-column: auto / span 6;  }
.Col--sm7  { grid-column: auto / span 7;  }
.Col--sm8  { grid-column: auto / span 8;  }
.Col--sm9  { grid-column: auto / span 9;  }
.Col--sm10 { grid-column: auto / span 10; }
.Col--sm11 { grid-column: auto / span 11; }
.Col--sm12 { grid-column: auto / span 12; }

@media (min-width: 720px) {
  .Grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }

  .Grid[data-items="1"] {
    grid-template-columns: 0.5fr 1fr 0.5fr;
  }

  .Grid[data-items="1"]::before,
  .Grid[data-items="1"]::after {
    content: "";
  }

  .Col--md0  { grid-column: 1/ -1;  }
  .Col--md1  { grid-column: auto / span 1;  }
  .Col--md2  { grid-column: auto / span 2;  }
  .Col--md3  { grid-column: auto / span 3;  }
  .Col--md4  { grid-column: auto / span 4;  }
  .Col--md5  { grid-column: auto / span 5;  }
  .Col--md6  { grid-column: auto / span 6;  }
  .Col--md7  { grid-column: auto / span 7;  }
  .Col--md8  { grid-column: auto / span 8;  }
  .Col--md9  { grid-column: auto / span 9;  }
  .Col--md10 { grid-column: auto / span 10; }
  .Col--md11 { grid-column: auto / span 11; }
  .Col--md12 { grid-column: auto / span 12; }
}

@media (min-width: 1024px) {
  .Grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .Grid[data-items="1"] {
    grid-template-columns: 1.5fr minmax(36rem, 1fr) 1.5fr;
  }

  .Grid[data-items="2"] {
    grid-template-columns: 1fr repeat(2, minmax(36rem, 1fr)) 1fr;
  }

  .Grid[data-items="3"] {
    grid-template-columns: 0.5fr repeat(3, minmax(26rem, 1fr)) 0.5fr;
  }

  .Grid[data-items="2"]::before,
  .Grid[data-items="2"]::after,
  .Grid[data-items="3"]::before,
  .Grid[data-items="3"]::after {
    content: "";
  }

  .Col--lg0  { grid-column: 1 / -1;  }
  .Col--lg1  { grid-column: auto / span 1;  }
  .Col--lg2  { grid-column: auto / span 2;  }
  .Col--lg3  { grid-column: auto / span 3;  }
  .Col--lg4  { grid-column: auto / span 4;  }
  .Col--lg5  { grid-column: auto / span 5;  }
  .Col--lg6  { grid-column: auto / span 6;  }
  .Col--lg7  { grid-column: auto / span 7;  }
  .Col--lg8  { grid-column: auto / span 8;  }
  .Col--lg9  { grid-column: auto / span 9;  }
  .Col--lg10 { grid-column: auto / span 10; }
  .Col--lg11 { grid-column: auto / span 11; }
  .Col--lg12 { grid-column: auto / span 12; }
}
