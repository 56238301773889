/* ASPECT RATIO FALLBACK */
/* Assuming that @supports is supported is fine, since we're also using CSS Grid which came out later. */

@supports not (aspect-ratio: 1) {
  .AspectRatio__Media {
    position: relative;
  }

  .AspectRatio__Media::before {
    content: "";
    display: block;
  }

  .AspectRatio__Image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
