/* LAYOUT */

.SiteFooter__Details {
  row-gap: 3rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.SiteFooter__Details--hasMenu {
  padding-bottom: 3rem;
}

.SiteFooter__Logo,
.SiteFooter__Disclaimer,
.SiteFooter__Nav {
  grid-column: 1 / -1;
  align-self: start;
}

.SiteFooter__Logo {
  padding-bottom: 1rem;
}

.SiteFooter__Copyright {
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
}

@media (min-width: 720px) {
  .SiteFooter__Details {
    padding-top: 5.8rem;
    padding-bottom: 5.8rem;
  }

  .SiteFooter__Details--hasMenu {
    padding-bottom: 3rem;
  }

  .SiteFooter__Logo {
    grid-column: 1 / span 2;
    padding-bottom: 0;
  }

  .SiteFooter__Disclaimer {
    grid-column: 3 / span all;
    max-width: 70rem;
  }

  .SiteFooter__Copyright {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
  }
}

/* CONTENT */

.SiteFooter {
  color: var(--footer-text-color, var(--text-inverted));
  background-color: var(--footer-background-color, var(--dark));
}

.SiteFooter__LogoImage {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 12rem;
  object-fit: cover;
  object-position: top center;
}

.SiteFooter__Heading {
  margin: 0;
  margin-bottom: 1.6rem;

  font-family: var(--font-primary);
  font-size: 1.8rem;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}

.SiteFooter__Text {
  margin: 0;

  font-size: 1.2rem;
  line-height: 1.55;
}

.SiteFooter__Text--small {
  font-size: 1rem;
  line-height: initial;
}

.SiteFooter__Nav {
  --site-footer-nav-items-spacing: 1.2rem;
  margin: calc(var(--site-footer-nav-items-spacing) / -2);
}

.SiteFooter__Nav__Link {
  display: inline-block;
  margin: calc(var(--site-footer-nav-items-spacing) / 2);

  font-size: 1.2rem;
  text-decoration: none;
}

.SiteFooter__Nav__Link:hover,
.SiteFooter__Nav__Link:focus {
  text-decoration: underline;
}

.SiteFooter__Separator {
  border: 1px solid currentColor;
  margin: 0;
}

.SiteFooter__Copyright__Separator {
  margin-left: 8px;
  margin-right: 8px;
}

.SiteFooter__Copyright__Link {
  font-weight: 700;
  text-decoration: none;
}

.SiteFooter__Copyright__Link:hover,
.SiteFooter__Copyright__Link:focus {
  text-decoration: underline;
}

@media (min-width: 720px) {
  .SiteFooter__LogoImage {
    max-height: 10rem;
  }

  .SiteFooter__Heading {
    font-size: 2.4rem;
  }

  .SiteFooter__Text {
    font-size: 1.6rem;
    line-height: 1.415;
  }

  .SiteFooter__Text--small {
    font-size: 1.4rem;
    line-height: initial;
  }

  .SiteFooter__Nav {
    --site-footer-nav-items-spacing: 2rem;
  }

  .SiteFooter__Nav__Link {
    font-size: 1.6rem;
  }
}
