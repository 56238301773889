.Block {
  --block-padding-top: 3.2rem;
  --block-padding-bottom: 4.8rem;

  padding-top: var(--block-padding-top, 3.2rem);
  padding-bottom: var(--block-padding-bottom, 4.8rem);

  color: var(--block-text-color, var(--text-primary));
  background: center center no-repeat;
  background-size: cover;
  background-color: var(--block-background-color, var(--background-primary));
  scroll-margin-top: var(--site-header-size);
  background-image: var(--bg-mobile-image-url, none);
}

.Block--inverted {
  color: var(--text-inverted);
}

.Block--evenlyPadded {
  padding-bottom: 3.2rem;
}

.Block--backgroundSizeFit {
  background-size: contain;
}

.Block--backgroundSizeFill {
  background-size: cover;
}

.Block--backgroundPlacementTop {
  background-position: top;
}

.Block--backgroundPlacementBottom {
  background-position: bottom;
}

.Block h1,
.Block h2,
.Block h3,
.Block h4,
.Block h5,
.Block h6 {
  font-family: var(--font-primary);
}

.Block__Header {
  margin-bottom: 2rem;
}

.Block__Footer {
  margin-top: 2rem;
  text-align: center;
}

.Block__Title {
  margin: 0;

  font-family: var(--font-primary);
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  text-transform: initial;
}

.Block__Title--uppercase {
  text-transform: uppercase;
}

@media (min-width: 460px) {
  .Block {
    background-image: var(--bg-image-url, none);
  }
}

@media (min-width: 720px) {
  .Block {
    --block-padding-top: 6rem;
    --block-padding-bottom: 8.2rem;

    padding-top: var(--block-padding-top, 6rem);
    padding-bottom: var(--block-padding-bottom, 8.2rem);
  }

  .Block--evenlyPadded {
    padding-bottom: 6rem;
  }

  .Block__Header {
    margin-bottom: 3rem;
  }

  .Block__Footer {
    margin-top: 3rem;
  }

  .Block__Title {
    font-size: 4.8rem;
    line-height: initial;
  }
}

@media (min-width: 1024px) {
  .Block__Header {
    margin-bottom: 5.2rem;
  }

  .Block__Footer {
    margin-top: 5.6rem;
  }

  .Block__Title {
    font-size: 5.6rem;
  }
}
