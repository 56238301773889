.SiteMessages {
  padding: 0;
  margin: 0;

  list-style: none;
}

.Wrapper--siteMessages {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.Wrapper--siteMessages::before {
  content: "";
  flex-shrink: 0;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  margin-right: 1.6rem;

  background-color: var(--secondary);
}

.SiteMessages__Item {
  font-size: 1.4rem;
  color: var(--text-inverted);
  background-color: var(--primary-light);
}

.SiteMessages__Item:not(:last-child) {
  border-bottom: 1px solid var(--primary);
}

@media (min-width: 720px) {
  .Wrapper--siteMessages::before {
    width: 0.8rem;
    height: 0.8rem;
  }

  .SiteMessages__Item {
    font-size: 1.8rem;
  }
}
